import React, { useEffect, useState } from "react";
import { useAppContext } from "../../contexts/AppContext";
import { ChevronDownIcon, ChevronUpIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { AssignedSessionView } from "../../types/AssignedSessionView";
import { AssignedSessionService } from "../../services/AssignedSessionService";
import UserAssignedSessionCard from "../Utility/UserAssignedSessionCard";
import { AssignmentService } from "../../services/AssignmentService";
import { UserAssignmentView } from "../../types/UserAssignmentView";
import CompletedSessionCard from "../Utility/CompletedSessionCard";
import Spinner from "../Utility/Spinner";
import { Button } from "../../components/shadcn-ui/Button";
import { Card } from "../shadcn-ui/Card";
import UserAssignmentCard from "../Utility/UserAssignmentCard";
import { BriefcaseBusiness, Feather, UserCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";
import TokenManager from "../../services/TokenManager";

// Main component for the user's homepage
const UserHomepage: React.FC = () => {
	const { userId } = useAppContext();
	const navigate = useNavigate();

	// State variables for different types of sessions and assignments
	const [availableDueDateStats, setAvailableDueDateStats] = useState<AssignedSessionView[]>([]);
	const [pendingDueDateStats, setPendingDueDateStats] = useState<AssignedSessionView[]>([]);
	const [completedDueDateStats, setCompletedDueDateStats] = useState<AssignedSessionView[]>([]);
	const [inProgressDueDateStats, setInProgressDueDateStats] = useState<AssignedSessionView[]>([]);
	const [userAssignments, setUserAssignments] = useState<UserAssignmentView[]>([]);

	// Loading states for different data fetching operations
	const [loadingAvailable, setLoadingAvailable] = useState(true);
	const [loadingInProgress, setLoadingInProgress] = useState(true);
	const [loadingPending, setLoadingPending] = useState(true);
	const [loadingCompleted, setLoadingCompleted] = useState(true);
	const [loadingAssignments, setLoadingAssignments] = useState(true);

	useEffect(() => {
		// Fetch data when the component mounts or userId changes
		const fetchEarliestPendingDueDateStats = async () => {
			if (!userId) return;
			try {
				const data = await AssignedSessionService.getAvailableByUserId(userId);
				setAvailableDueDateStats(data);
			} catch (error) {
				console.error("Error fetching earliest pending due date stats:", error);
			} finally {
				setLoadingAvailable(false);
			}
		};

		const fetchInProgressDueDateStats = async () => {
			if (!userId) return;
			try {
				const data = await AssignedSessionService.getInProgressByUserId(userId);
				setInProgressDueDateStats(data);
			} catch (error) {
				console.error("Error fetching in progress due date stats:", error);
			} finally {
				setLoadingInProgress(false);
			}
		};

		const fetchPendingDueDateStats = async () => {
			if (!userId) return;
			try {
				const data = await AssignedSessionService.getPendingByUserId(userId);
				setPendingDueDateStats(data);
			} catch (error) {
				console.error("Error fetching pending due date stats:", error);
			} finally {
				setLoadingPending(false);
			}
		};

		const fetchCompletedDueDateStats = async () => {
			if (!userId) return;
			try {
				const data = await AssignedSessionService.getCompletedByUserId(userId);
				setCompletedDueDateStats(data);
			} catch (error) {
				console.error("Error fetching completed due date stats:", error);
			} finally {
				setLoadingCompleted(false);
			}
		};

		const fetchUserAssignments = async () => {
			if (!userId) return;
			try {
				const data = await AssignmentService.getByUserId(userId);
				setUserAssignments(data);
			} catch (error) {
				console.error("Error fetching user assignments:", error);
			} finally {
				setLoadingAssignments(false);
			}
		};

		// Call all fetch functions
		fetchUserAssignments();
		fetchEarliestPendingDueDateStats();
		fetchInProgressDueDateStats();
		fetchPendingDueDateStats();
		fetchCompletedDueDateStats();
	}, [userId]);

	// useEffect(() => {
	// 	const checkVisit = async () => {
	// 		const isFirstVisit = await checkPageVisit("userDashboard");
	// 		// console.log(`Is first visit: ${isFirstVisit}`); // Debugging
	// 		// these are flipped
	// 		if (isFirstVisit) {
	// 			// console.log("Welcome back to this page");
	// 		} else {
	// 			// console.log("This is your first visit to this page.");
	// 			if (window.Intercom) {
	// 				window.Intercom("show");
	// 			}
	// 			// Logic for subsequent visits
	// 		}
	// 	};

	// 	checkVisit();
	// }, [checkPageVisit]);

	// Create elements for different types of sessions and assignments
	const availableDueDateUserStatsElements = availableDueDateStats
		.sort((a, b) => a.scheduleNumber - b.scheduleNumber)
		.map((stat) => <UserAssignedSessionCard key={stat.scheduleId} stat={stat} userId={userId} />);

	const inProgressDueDateUserStatsElements = inProgressDueDateStats
		.sort((a, b) => a.scheduleNumber - b.scheduleNumber)
		.map((stat) => <UserAssignedSessionCard key={stat.scheduleId} stat={stat} userId={userId} />);

	const pendingDueDateUserStatsElements = pendingDueDateStats
		.sort((a, b) => a.scheduleNumber - b.scheduleNumber)
		.map((stat) => <UserAssignedSessionCard key={stat.scheduleId} stat={stat} userId={userId} isPending={true} />);

	const completedDueDateUserStatsElements = completedDueDateStats
		.sort((a, b) => a.scheduleNumber - b.scheduleNumber)
		.map((stat) => <CompletedSessionCard key={stat.scheduleId} stat={stat} userId={userId} />);

	const userAssignmentElements = userAssignments.map((assignment) => <UserAssignmentCard key={assignment.assignmentId} assignment={assignment} />);

	// Component to display when no items are available
	const NoItemsCard: React.FC<{ message: string }> = ({ message }) => (
		<Card className="w-full min-h-[250px] bg-transparent flex items-center justify-center p-2">
			<ExclamationCircleIcon className="h-5 w-5 text-gray-400 mr-2" />
			<p className="text-gray-500">{message}</p>
		</Card>
	);

	const OtherInStageSimulationsCard: React.FC = () => {
		const simulations = [
			{
				title: "Interview Simulation",
				description: "Practice your interviewing skills in a realistic setting",
				link: "https://www.app.instage.io/setup/interview"
			},
			{
				title: "Sales Spin",
				description: "Learn to conduct effective discovery meetings with clients",
				link: "https://www.app.instage.io/setup/discovery_spin"
			},
			{
				title: "Presentations",
				description: "Enhance your presentation and public speaking abilities",
				link: "https://www.app.instage.io/setup/presentation"
			}
		];

		return (
			<Card className="w-full bg-white p-6">
				<div className="grid gap-4">
					{simulations.map((sim, index) => (
						<div key={index}>
							<div
								className={`flex items-center justify-between p-0 transition-colors ${index < simulations.length - 1 ? "pb-0" : ""}`}
							>
								<div className="text-left">
									<h3 className="text-xl font-semibold text-[#00a9af] mb-2 transition-colors">{sim.title}</h3>
									<p className="text-gray-600 transition-colors group-hover:text-opacity-30">{sim.description}</p>
								</div>
								<Button variant="outline" size="sm" onClick={() => window.open(sim.link, "_blank")}>
									Try Now
								</Button>
							</div>
							{index < simulations.length - 1 && <hr className="border-gray-200 my-3" />}
						</div>
					))}
				</div>
			</Card>
		);
	};

	// Component to display a vertical stack of cards with a "Show More" option
	const VerticalCardStack: React.FC<{ elements: React.ReactNode[]; emptyMessage: string }> = ({ elements, emptyMessage }) => {
		const [showAll, setShowAll] = useState(false);
		const hasMoreThanOne = elements.length > 1;

		return (
			<div className="mb-6">
				<div className="space-y-4">
					{elements.length > 0 ? (
						<>
							{elements[0]}
							{hasMoreThanOne && (
								<div className="mt-4">
									<Button variant="ghost" size="sm" className="p-0" onClick={() => setShowAll(!showAll)}>
										{showAll ? (
											<>
												Show Less <ChevronUpIcon className="ml-2 h-4 w-4" />
											</>
										) : (
											<>
												Show More <ChevronDownIcon className="ml-2 h-4 w-4" />
											</>
										)}
									</Button>
									{showAll && (
										<div className="space-y-4 mt-4">
											{elements.slice(1).map((element, index) => (
												<React.Fragment key={index}>{element}</React.Fragment>
											))}
										</div>
									)}
								</div>
							)}
						</>
					) : (
						<NoItemsCard message={emptyMessage} />
					)}
				</div>
			</div>
		);
	};

	// Render the main component
	return (
		<div className="h-full max-w-3xl mx-auto px-4">
			<h1 className="text-3xl font-bold text-[#16013e] mb-10">Hello there 👋</h1>

			{/* In Progress Reflection Calls Section */}
			{inProgressDueDateUserStatsElements.length > 0 && (
				<div className="mb-8">
					<h2 className="text-lg font-bold text-[#16013e] mb-4">Unsubmitted Reflection Calls</h2>
					{loadingInProgress ? <Spinner /> : inProgressDueDateUserStatsElements}
				</div>
			)}

			{/* Available Reflection Calls Section */}
			<div className="mb-8">
				<h2 className="text-lg font-bold text-[#16013e] mb-4">Available Reflection Calls</h2>
				{loadingAvailable ? (
					<Spinner />
				) : availableDueDateUserStatsElements.length > 0 ? (
					<div className="space-y-4">{availableDueDateUserStatsElements}</div>
				) : (
					<div className="w-full h-[56px] rounded-[12px] border border-dashed border-[#171717] border-opacity-20 flex items-center justify-center">
						<p className="text-[#3C3C3F] opacity-70">No active reflection calls</p>
					</div>
				)}
			</div>

			{/* Stack all sections vertically */}
			<div className="space-y-8">
				{/* Upcoming Reflections Section */}
				<div>
					<h2 className="text-lg font-bold text-[#16013e] mb-4">Upcoming Reflection Calls</h2>
					{loadingPending ? (
						<Spinner />
					) : pendingDueDateUserStatsElements.length > 0 ? (
						<div className="relative">
							<div className="overflow-x-auto pb-4 hide-scrollbar">
								<div className="flex space-x-4">
									{pendingDueDateUserStatsElements.map((element, index) => (
										<div key={index} className="w-full sm:w-[calc(50%-8px)] md:w-[calc(33.333%-11px)] flex-shrink-0">
											{element}
										</div>
									))}
								</div>
							</div>
							{pendingDueDateUserStatsElements.length > 3 && (
								<div className="absolute top-0 right-0 bottom-0 w-16 bg-gradient-to-l from-white to-transparent pointer-events-none"></div>
							)}
						</div>
					) : (
						<div className="w-full h-[56px] rounded-[12px] border border-dashed border-[#171717] border-opacity-20 flex items-center justify-center">
							<p className="text-[#3C3C3F] opacity-70">No upcoming reflection calls</p>
						</div>
					)}
				</div>

				{/* Create Your Own Section */}
				<div className="mb-8">
					<div className="flex items-center gap-2 mb-4">
						<h2 className="text-lg font-bold text-[#16013e]">Create Your Own</h2>
						<div className="h-5 w-[1px] bg-gray-200"></div>
						<span className="inline-flex items-center px-2 py-0.5 text-xs font-medium rounded bg-purple-100 text-purple-800 border border-purple-200">
							<UserCircle className="w-3 h-3 mr-1" />
							Self Practice
						</span>
					</div>
					{loadingCompleted ? (
						<Spinner />
					) : (
						<div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
							<Button
								variant="outlineTransparent"
								className="h-[56px] flex items-center justify-center gap-2"
								onClick={() => navigate("/user/create-reflection")}
							>
								<Feather className="h-4 w-4" />
								Create Reflection
							</Button>
							{TokenManager.hasInstageAdminClearance() && (
								<Button
									variant="outlineTransparent"
									className="h-[56px] flex items-center justify-center gap-2"
									onClick={() => navigate("/user/create-interview")}
								>
									<BriefcaseBusiness className="h-4 w-4" />
									Create Interview
								</Button>
							)}
						</div>
					)}
				</div>

				{/* Recent Session Summaries Section */}
				<div>
					<h2 className="text-lg font-bold text-[#16013e] mb-4">Recent Call Summaries</h2>
					{loadingCompleted ? (
						<Spinner />
					) : completedDueDateUserStatsElements.length > 0 ? (
						<VerticalCardStack elements={completedDueDateUserStatsElements} emptyMessage="No recent summaries available." />
					) : (
						<div className="w-full h-[56px] rounded-[12px] border border-dashed border-[#171717] border-opacity-20 flex items-center justify-center">
							<p className="text-[#3C3C3F] opacity-70">No recent calls</p>
						</div>
					)}
				</div>

				{/* My Assignments Section */}
				<div>
					<h2 className="text-lg font-bold text-[#16013e] mb-4">All Assignments</h2>
					{loadingAssignments ? (
						<Spinner />
					) : userAssignmentElements.length > 0 ? (
						<div className="space-y-4">{userAssignmentElements}</div>
					) : (
						<div className="w-full h-[56px] rounded-[12px] border border-dashed border-[#171717] border-opacity-20 flex items-center justify-center">
							<p className="text-[#3C3C3F] opacity-70">No assignments</p>
						</div>
					)}
				</div>

				<div>
					<h2 className="text-lg font-bold text-[#16013e] mb-4">Other InStage Simulations</h2>

					<OtherInStageSimulationsCard />
				</div>
			</div>
		</div>
	);
};

export default UserHomepage;
