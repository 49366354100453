export enum ExperienceType {
	// SessionType.REFLECTION CO-OP
	CO_OP_EXPERIENCE = "Co-op Experience",
	INTERNSHIP = "Internship",
	FIELD_PLACEMENT = "Field Placement",
	CAPSTONE_PROJECT = "Capstone Project",
	WEEKLY_SPRINT = "Weekly Sprint",
	MONTHLY_PROJECT = "Monthly Project",

	// SessionType.REFLECTION COURSE
	COURSE = "Course",

	// SessionType.INBOUND
	CONFERENCE = "Conference",
	DEMO = "Demo",
	INTERVIEW = "Interview"
}
