import React, { useState, useEffect } from "react";
import { UserService } from "../../services/UserService";
import { DataTable, ExtendedColumnDef, Filter } from "../shadcn-ui/DataTable";
import { useAppContext, QueueItem, VapiCallList } from "../../contexts/AppContext";
import { DataTableColumnHeader } from "../shadcn-ui/DataTableColumnHeader";
import { DataTableActionMenu } from "../shadcn-ui/DataTableActionMenu";

interface ConnectedUser {
	userId: string;
	socketId: string;
	isInCall: boolean;
	email: string;
	firstName: string;
	lastName: string;
	clientName: string;
	tenantId: string;
	clientId: string;
}

const ConnectedUsersPage: React.FC = () => {
	const [connectedUsers, setConnectedUsers] = useState<ConnectedUser[]>([]);
	const { userId, queueData, activeUsers, vapiCalls, emit } = useAppContext();
	useEffect(() => {
		const fetchConnectedUsers = async () => {
			// console.log("Fetching connected users");
			// const users = await UserService.getConnectedUsers();
			// // console.log("Connected users:", users);
			// if (users) {
			// 	setConnectedUsers(users);
			// }
		};

		fetchConnectedUsers();

		const intervalId = setInterval(fetchConnectedUsers, 30000);

		return () => clearInterval(intervalId);
	}, [userId]);

	// useEffect(() => {
	// 	const checkIfAvailable = async () => {
	// 		const data = await emit("checkIfAvailable", { userId });
	// 		console.log(data);
	// 	};
	// 	const interval = setInterval(() => {
	// 		checkIfAvailable();
	// 	}, 30000);
	// 	return () => clearInterval(interval);
	// }, [emit, userId]);

	const columns: ExtendedColumnDef<ConnectedUser, any>[] = [
		{
			accessorKey: "firstName",
			header: "First Name"
		},
		{
			accessorKey: "lastName",
			header: "Last Name"
		},
		{
			accessorKey: "email",
			header: "Email"
		},
		{
			accessorKey: "clientName",
			header: "Client"
		},
		{
			accessorKey: "isInCall",
			header: "In Call",
			cell: ({ row }) => (row.getValue("isInCall") ? "Yes" : "No")
		},
		{
			accessorKey: "userId",
			header: "User ID"
		},
		{
			accessorKey: "socketId",
			header: "Socket ID"
		},
		{
			accessorKey: "tenantId",
			header: "Tenant ID"
		},
		{
			accessorKey: "clientId",
			header: "Client ID"
		}
	];

	const filters: Filter[] = [
		{
			columnId: "email",
			type: "input",
			placeholder: "Filter by email..."
		},
		{
			columnId: "clientName",
			type: "input",
			placeholder: "Filter by client..."
		},
		{
			columnId: "isInCall",
			type: "select",
			placeholder: "Filter by call status",
			options: [
				{ label: "In Call", value: "true" },
				{ label: "Not In Call", value: "false" }
			]
		}
	];
	const columnsQueue: ExtendedColumnDef<QueueItem, any>[] = [
		{
			accessorKey: "position",
			header: "Position"
		},
		{
			accessorKey: "userId",
			header: "User ID"
		},
		{
			accessorKey: "joinedAt",
			header: "Joined At",
			cell: ({ row }) => {
				const date = row.getValue("joinedAt") as Date;
				return <div>{date.toLocaleString()}</div>;
			}
		},
		{
			accessorKey: "socketId",
			header: "Socket ID",
			cell: ({ row }) => {
				const socketId = row.getValue("socketId") as string;
				return <div>{socketId}</div>;
			}
		},
		{
			accessorKey: "canMoveToActive",
			header: ({ column }) => <DataTableColumnHeader column={column} title="Can Move to Active" />,
			cell: ({ row }) => {
				const canMove = row.getValue("canMoveToActive") as boolean;
				return <div>{canMove ? "Yes" : "No"}</div>;
			}
		},
		{
			accessorKey: "clearanceLevel",
			header: ({ column }) => <DataTableColumnHeader column={column} title="Clearance Level" />,
			cell: ({ row }) => {
				const clearanceLevel = row.getValue("clearanceLevel") as number;
				return <div>{clearanceLevel}</div>;
			}
		},
		{
			id: "actions",
			cell: ({ row }) => {
				const queueItem = row.original;
				return <DataTableActionMenu label="Actions" copyId={queueItem.userId} menuItems={[]} />;
			}
		}
	];

	// Update the columns for the active users table
	const columnsActive: ExtendedColumnDef<QueueItem, any>[] = [
		{
			accessorKey: "position",
			header: "Position"
		},
		{
			accessorKey: "userId",
			header: "User ID"
		},

		{
			accessorKey: "vapiCallId",
			header: "Vapi Call ID"
		},
		{
			accessorKey: "socketId",
			header: "Socket ID",
			cell: ({ row }) => {
				const socketId = row.getValue("socketId") as string;
				return <div>{socketId}</div>;
			}
		},
		{
			accessorKey: "joinedAt",
			header: "Joined At",
			cell: ({ row }) => {
				const date = row.getValue("joinedAt") as Date;
				return <div>{date.toLocaleString()}</div>;
			}
		},
		{
			accessorKey: "clearanceLevel",
			header: ({ column }) => <DataTableColumnHeader column={column} title="Clearance Level" />,
			cell: ({ row }) => {
				const clearanceLevel = row.getValue("clearanceLevel") as number;
				return <div>{clearanceLevel}</div>;
			}
		},
		{
			id: "actions",
			cell: ({ row }) => {
				const queueItem = row.original;
				return <DataTableActionMenu label="Actions" copyId={queueItem.userId} menuItems={[]} />;
			}
		}
	];

	const vapiCallsColumns: ExtendedColumnDef<VapiCallList, any>[] = [
		{
			accessorKey: "id",
			header: "ID"
		},
		{
			accessorKey: "name",
			header: "Name"
		}
	];

	return (
		<div className="container mx-auto py-10">
			<h1 className="text-3xl font-bold mb-6"> Users on site</h1>
			<DataTable columns={columns} data={connectedUsers} filters={filters} />

			<div className="mb-4">
				<h2 className="text-2xl font-bold mb-4">Users in Queue</h2>
				<DataTable columns={columnsQueue} data={queueData} />
			</div>
			<div className="mb-4">
				<h2 className="text-2xl font-bold mb-4">Active Users</h2>
				<DataTable columns={columnsActive} data={activeUsers} />
			</div>
			<div className="mb-4">
				<h2 className="text-2xl font-bold mb-4">Vapi Calls</h2>
				<DataTable columns={vapiCallsColumns} data={vapiCalls} />
			</div>
		</div>
	);
};

export default ConnectedUsersPage;
