import { Expose } from "class-transformer";

import { IsUUID, IsString, IsBoolean } from "class-validator";
import { ViewColumn } from "./typeorm-mocks";

export class UserView {
	@Expose()
	@ViewColumn()
	@IsUUID()
	id!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	email!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	firstName!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	lastName!: string;

	@Expose()
	@ViewColumn()
	@IsBoolean()
	hasDoneTutorial!: boolean;

	@Expose()
	@ViewColumn()
	@IsUUID()
	clientId!: string;

	@Expose()
	@ViewColumn()
	@IsUUID()
	tenantId!: string | null;

	@Expose()
	@ViewColumn()
	@IsString()
	clientName!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	fronteggId!: string | null;
}
