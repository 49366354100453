import { Expose, Transform } from "class-transformer";
import { IsUUID, IsString, IsDate, IsOptional, IsEnum, IsBoolean, IsNumber } from "class-validator";
import { dateTransformer } from "./dateTransformer";
import { numberTransformer } from "./numberTransformer";
import { ViewColumn } from "./typeorm-mocks";
import { AssignedSessionStatus } from "./AssignedSessionStatus";
import { JobQuestion } from "./InterviewPayload";
import { InterviewStageType } from "./InterviewPayload";

export enum ActivityType {
	FIRST = "first",
	MIDDLE = "middle",
	LAST = "last"
}

export class AssignedSessionView {
	@Expose()
	@ViewColumn()
	@IsUUID()
	scheduleId!: string;

	@Expose()
	@ViewColumn()
	@IsDate()
	@Transform(({ value }) => dateTransformer(value))
	date!: Date;

	@Expose()
	@ViewColumn()
	@IsDate()
	@Transform(({ value }) => dateTransformer(value))
	startDate!: Date;

	@Expose()
	@ViewColumn()
	@IsString()
	scheduleDescription!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	assignmentDescription!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	email!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	fullName!: string;

	@Expose()
	@ViewColumn()
	@IsEnum(AssignedSessionStatus)
	status!: AssignedSessionStatus;

	@Expose()
	@ViewColumn()
	@IsEnum(ActivityType)
	activityType!: ActivityType;

	@Expose()
	@ViewColumn()
	@IsNumber()
	@Transform(({ value }) => numberTransformer(value))
	scheduleNumber!: number;

	@Expose()
	@ViewColumn()
	@IsNumber()
	@Transform(({ value }) => numberTransformer(value))
	scheduleCount!: number;

	@Expose()
	@ViewColumn()
	@IsOptional()
	@IsUUID()
	assignedSessionId!: string | null;

	@Expose()
	@ViewColumn()
	@IsString()
	assignmentTitle!: string;

	@Expose()
	@ViewColumn()
	@IsUUID()
	assignmentId!: string;

	@ViewColumn()
	@IsOptional()
	@IsString()
	assignmentCode!: string;

	@Expose()
	@ViewColumn()
	@IsUUID()
	userId!: string;

	@Expose()
	@ViewColumn()
	@IsOptional()
	@IsUUID()
	sessionId!: string | null;

	@Expose()
	@ViewColumn()
	@IsOptional()
	@IsDate()
	@Transform(({ value }) => dateTransformer(value))
	submittedAt!: Date;

	@Expose()
	@ViewColumn()
	@IsString()
	firstName!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	lastName!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	fronteggId!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	courseName!: string;

	@Expose()
	@ViewColumn()
	@IsOptional()
	@IsUUID()
	courseId!: string | null;

	@Expose()
	@ViewColumn()
	@IsOptional()
	@IsNumber()
	@Transform(({ value }) => numberTransformer(value))
	grade!: number | null;

	@Expose()
	@ViewColumn()
	@IsOptional()
	@IsBoolean()
	isLate!: boolean | null;

	@Expose()
	@ViewColumn()
	@IsOptional()
	@IsDate()
	@Transform(({ value }) => dateTransformer(value))
	sessionCreatedAt!: Date | null;

	@Expose()
	@ViewColumn()
	@IsOptional()
	sessionDuration!: { days: number; hours: number; minutes: number; seconds: number; milliseconds: number } | null;

	@Expose()
	@ViewColumn()
	@IsOptional()
	@IsBoolean()
	includeTranscript!: boolean;

	@Expose()
	@ViewColumn()
	@IsOptional()
	@IsBoolean()
	includeAudio!: boolean;

	@IsBoolean()
	@ViewColumn()
	includeUserIdentification!: boolean;

	@IsBoolean()
	@ViewColumn()
	soloMode!: boolean;

	@Expose()
	@ViewColumn()
	@IsOptional()
	@IsBoolean()
	userConfirmed!: boolean;

	@Expose()
	@ViewColumn()
	@IsOptional()
	@IsDate()
	@Transform(({ value }) => dateTransformer(value))
	scheduledCallTime!: Date | null;

	@Expose()
	@ViewColumn()
	@IsOptional()
	@IsString()
	scheduledCallBookingId!: string | null;

	@Expose()
	@ViewColumn()
	@IsOptional()
	@IsNumber()
	@Transform(({ value }) => numberTransformer(value))
	daysToAdd!: number | null;

	@Expose()
	@ViewColumn()
	@IsOptional()
	@IsString()
	attendeeSeatId!: string | null;

	@Expose()
	@ViewColumn()
	@IsString()
	@IsOptional()
	interviewStageType!: InterviewStageType | null;

	@Expose()
	@ViewColumn()
	@IsNumber()
	@IsOptional()
	interviewStageOrderNumber!: number | null;

	@Expose()
	@ViewColumn()
	@IsString()
	@IsOptional()
	interviewerAttitude!: string | null;

	@Expose()
	@ViewColumn()
	@IsOptional()
	interviewQuestionList!: JobQuestion[] | null;

	@Expose()
	@ViewColumn()
	@IsString()
	@IsOptional()
	jobTitle!: string | null;

	@Expose()
	@ViewColumn()
	@IsString()
	@IsOptional()
	jobDescription!: string | null;

	@Expose()
	@ViewColumn()
	@IsString()
	@IsOptional()
	jobSummary!: string | null;

	@Expose()
	@ViewColumn()
	@IsString()
	@IsOptional()
	jobFunction!: string | null;

	@Expose()
	@ViewColumn()
	@IsString()
	@IsOptional()
	jobExperienceLevel!: string | null;

	@Expose()
	@ViewColumn()
	@IsString({ each: true })
	@IsOptional()
	jobResponsibilities!: string[] | null;

	@Expose()
	@ViewColumn()
	@IsString({ each: true })
	@IsOptional()
	jobSkillsRequired!: string[] | null;
}
