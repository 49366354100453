import React, { useState, useEffect } from "react";
import { format, parseISO, startOfDay, endOfDay, isAfter, max } from "date-fns";
import { Calendar } from "../shadcn-ui/Calendar";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../shadcn-ui/Select";
import { Button } from "../shadcn-ui/Button";
import { Card, CardContent } from "../shadcn-ui/Card";
import { CalComService, Slot } from "../../services/CalComService";
import { FieldError, FieldErrorsImpl, useForm } from "react-hook-form";
import { Input } from "../shadcn-ui/Input";
import { Label } from "../shadcn-ui/Label";
import Spinner from "../Utility/Spinner";
import posthog from "posthog-js";
import { useAppContext } from "../../contexts/AppContext";

interface CalendarBookingProps {
	eventTypeId: string;
	eventTypeSlug: string;
	seatsPerTimeSlot: number;
	onBookingSuccess: (time: string) => void;
	userEmail: string;
	userName: string;
	userPhone: string;
	metadata: {
		attendeeEmail: string;
		assignedSessionId: string;
		provider: string;
		voiceId: string;
		assistantName: string;
	};
	minDate: Date;
	maxDate: Date;
	hideEmailField?: boolean;
}

const CalendarBooking: React.FC<CalendarBookingProps> = ({
	eventTypeId,
	eventTypeSlug,
	seatsPerTimeSlot,
	onBookingSuccess,
	userEmail,
	userName,
	userPhone,
	metadata,
	minDate,
	maxDate,
	hideEmailField = false
}) => {
	const [selectedDate, setSelectedDate] = useState<Date>(() => {
		const today = new Date();
		return max([today, minDate]);
	});
	const [availableSlots, setAvailableSlots] = useState<Slot[]>([]);
	const [selectedSlot, setSelectedSlot] = useState<Slot | null>(null);
	const [bookingError, setBookingError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const { instageUser } = useAppContext();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		watch // Add this to watch form values
	} = useForm({
		defaultValues: {
			name: userName,
			email: userEmail,
			phone: userPhone
		}
	});

	const nameValue = watch("name"); // Watch the name field value

	useEffect(() => {
		// Update selectedDate when minDate changes
		setSelectedDate((prevDate) => {
			const today = new Date();
			const newSelectedDate = max([today, minDate, prevDate]);

			try {
				posthog.capture("MINOR: Default Calendar Date Selected", {
					userId: instageUser?.fronteggId,
					timestamp: new Date().toISOString(),
					bookedFor: newSelectedDate
				});
			} catch (err) {
				console.error("Error capturing event 'MINOR: Default Calendar Date Selected':", err);
			}
			return isAfter(newSelectedDate, maxDate) ? maxDate : newSelectedDate;
		});
	}, [minDate, maxDate]);

	useEffect(() => {
		// Prefill the form fields
		setValue("name", userName);
		setValue("email", userEmail);
		setValue("phone", userPhone);
	}, [userName, userEmail, userPhone, setValue]);

	useEffect(() => {
		const fetchSlots = async () => {
			const startDate = startOfDay(selectedDate);
			const endDate = endOfDay(selectedDate);

			try {
				const slots = await CalComService.getAvailableSlots(startDate.toISOString(), endDate.toISOString(), eventTypeId, eventTypeSlug);
				setAvailableSlots(slots);
			} catch (error) {
				console.error("Error fetching available slots:", error);
			}
		};

		fetchSlots();
	}, [eventTypeId, eventTypeSlug, selectedDate]);

	const handleDateSelect = (date: Date | undefined) => {
		if (date) {
			setSelectedDate(date);
			setSelectedSlot(null);
			try {
				posthog.capture("MINOR: Calendar Date Selected", {
					userId: instageUser?.fronteggId,
					timestamp: new Date().toISOString(),
					bookedFor: date
				});
			} catch (err) {
				console.error("Error capturing event 'MINOR: Calendar Date Selected':", err);
			}
		}
	};

	const handleSlotSelect = (value: Slot) => {
		try {
			posthog.capture("MINOR: Calendar Time Slot Selected", {
				userId: instageUser?.fronteggId,
				timestamp: new Date().toISOString(),
				bookedFor: value.time
			});
		} catch (err) {
			console.error("Error capturing event 'MINOR: Calendar Time Slot Selected':", err);
		}
		setSelectedSlot(value);
	};

	const onSubmit = async (formData: any) => {
		if (selectedSlot) {
			try {
				setBookingError(null);
				setIsLoading(true);
				const attendee = {
					name: formData.name,
					// email: formData.email,
					email: `hello+${formData.email.split("@")[0]}@instage.io`,
					timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
				};

				const bookingData = {
					start: selectedSlot.time,
					eventTypeId: parseInt(eventTypeId),
					attendee,
					phone: formData.phone,
					metadata
				};

				const response = await CalComService.createBooking(bookingData);
				console.log("Booking created:", response);
				onBookingSuccess(format(parseISO(selectedSlot.time), "PPp"));
			} catch (error: any) {
				console.error("Booking failed:", error);
				if (error.response?.status === 409) {
					setBookingError(
						error.response.data.error ||
							"You already have a scheduled call. Please complete or cancel your existing call before scheduling a new one."
					);
				} else {
					const errorMessage = error.response?.data?.error || error.message || "Failed to create booking. Please try again.";
					setBookingError(errorMessage);
				}
			} finally {
				try {
					const printObject = {
						fronteggId: instageUser?.fronteggId || "", // need frontegg id
						userId: instageUser?.id || "",
						tenantId: instageUser?.tenantId || "", // need tenant id
						clientId: instageUser?.clientId || "",
						timestamp: new Date().toISOString()
					};
					posthog.capture("MAJOR_EVENT: Booked Call Successfully", {
						printObject
					});
				} catch (err) {
					console.error("Error capturing event 'MAJOR_EVENT: Booked Call Successfully':", err);
				}
				setIsLoading(false);
			}
		} else {
			setBookingError("Please select a time slot before booking.");
		}
	};

	const getErrorMessage = (error: FieldError | FieldErrorsImpl<any> | undefined): string | null => {
		if (error) {
			if ("message" in error && typeof error.message === "string") {
				return error.message;
			}
			if ("type" in error) {
				return `${error.type} error occurred`;
			}
		}
		return null;
	};

	const getAvailableSlots = () => {
		return availableSlots.filter((slot) => {
			const attendees = slot.attendees || 0;
			return attendees < seatsPerTimeSlot;
		});
	};

	const getSlotAvailabilityText = (slot: Slot) => {
		const availableSlots = seatsPerTimeSlot - (slot.attendees || 0);
		return `(${availableSlots} ${availableSlots === 1 ? "slot" : "slots"} available)`;
	};

	return (
		<Card className="w-full max-w-md mx-auto border-transparent">
			<CardContent className="p-0">
				<form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
					<div className="space-y-4">
						<Calendar
							mode="single"
							selected={selectedDate}
							onSelect={handleDateSelect}
							className="rounded-md border"
							minDate={minDate}
							maxDate={maxDate}
						/>
						<Select
							onValueChange={(value: string) => {
								const slot = getAvailableSlots().find((s) => s.time === value);
								if (slot) handleSlotSelect(slot);
							}}
							value={selectedSlot?.time || ""}
						>
							<SelectTrigger className="w-full">
								<SelectValue placeholder="Select a time slot">
									{selectedSlot && (
										<div className="flex justify-between items-center w-full">
											<span className="inline-block w-24 text-left">{format(parseISO(selectedSlot.time), "h:mm a")}</span>
											<span className="text-sm text-gray-500">{getSlotAvailabilityText(selectedSlot)}</span>
										</div>
									)}
								</SelectValue>
							</SelectTrigger>
							<SelectContent>
								{getAvailableSlots().map((slot) => (
									<SelectItem key={slot.time} value={slot.time}>
										<div className="flex justify-between items-center w-full">
											<span className="inline-block w-24">{format(parseISO(slot.time), "h:mm a")}</span>
											<span className="text-sm text-gray-500">{getSlotAvailabilityText(slot)}</span>
										</div>
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					</div>
					<div>
						<Label htmlFor="name">
							Name <span className="text-red-500">*</span>
						</Label>
						<Input id="name" {...register("name", { required: "Name is required" })} />
						{getErrorMessage(errors.name) && <p className="text-red-500 text-sm">{getErrorMessage(errors.name)}</p>}
					</div>
					{/* Conditionally render the email field */}
					{!hideEmailField && (
						<div>
							<Label htmlFor="email">
								Email <span className="text-red-500">*</span>
							</Label>
							<Input id="email" type="email" value={userEmail} readOnly className="bg-gray-100 cursor-not-allowed" />
						</div>
					)}
					<div>
						<Label htmlFor="phone">
							Phone <span className="text-red-500">*</span>
						</Label>
						<Input id="phone" value={userPhone} readOnly className="bg-gray-100 cursor-not-allowed" />
					</div>
					{bookingError && <p className="text-red-500 text-sm">{bookingError}</p>}
					<Button type="submit" disabled={!selectedSlot || isLoading || !nameValue?.trim()} className="w-full">
						{isLoading ? <Spinner className="w-6 h-6" /> : "Book Call"}
					</Button>
				</form>
			</CardContent>
		</Card>
	);
};

export default CalendarBooking;
