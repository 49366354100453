import React, { useEffect } from "react";
import { FieldArray } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon, PlusIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon as CheckCircleOutlineIcon } from "@heroicons/react/24/outline";
import { Button } from "../../../shadcn-ui/Button";
import CustomTooltip from "../../../Utility/CustomTooltip";
import { Switch } from "../../../shadcn-ui/Switch";
import { InterviewStage, InterviewStageType } from "../../../../types/InterviewPayload";

// Define the structure of a session
interface Session {
	title: string;
	date: Date | null;
	startDate: Date | null;
	interview?: {
		interviewStageOrderNumber: number;
		interviewStageType: InterviewStageType;
		interviewerAttitude: string;
		interviewQuestionList: any[];
		jobTitle?: string;
		jobDescription?: string;
		jobSummary?: string;
		jobFunction?: string;
		jobExperienceLevel?: string;
		jobResponsibilities?: string[];
		jobSkillsRequired?: string[];
	};
}

// Define the structure of form values
interface Values {
	sessions: Session[];
	stages: InterviewStage[];
	useSharedDates: boolean;
	sharedStartDate: Date | null;
	sharedDueDate: Date | null;
	[key: string]: any;
}

// Define props for the InterviewDates component
export interface InterviewDatesProps {
	values: Values;
	errors: any;
	touched: any;
	setFieldValue: (field: string, value: any) => void;
	onNext: () => void;
	onPrevious: () => void;
	isValid: boolean;
	dirty: boolean;
	isEditMode: boolean;
}

// Define a helper component for DateRangePicker
interface DateRangePickerProps {
	startDate: Date | null;
	endDate: Date | null;
	onStartDateChange: (date: Date | null) => void;
	onEndDateChange: (date: Date | null) => void;
	disabled?: boolean;
	minDate?: Date;
	maxDate?: Date;
	minEndDate?: Date;
}

// Update the DateRangePicker component
const DateRangePicker: React.FC<DateRangePickerProps> = ({
	startDate,
	endDate,
	onStartDateChange,
	onEndDateChange,
	disabled,
	minDate,
	maxDate,
	minEndDate
}) => {
	// Convert null to undefined for react-datepicker
	const convertNullToUndefined = (date: Date | null): Date | undefined => {
		return date || undefined;
	};

	// Helper function to set default start date
	const handleEndDateChange = (date: Date | null) => {
		if (date && minEndDate && date < minEndDate) {
			return;
		}
		onEndDateChange(date);
		if (date) {
			if (!startDate || startDate > date) {
				const defaultStartDate = new Date(date);
				defaultStartDate.setDate(defaultStartDate.getDate() - 5);
				// Ensure default start date isn't before minDate
				if (minDate && defaultStartDate < minDate) {
					defaultStartDate.setTime(minDate.getTime());
				}
				onStartDateChange(defaultStartDate);
			}
		}
	};

	// Validate start date is not after end date
	const handleStartDateChange = (date: Date | null) => {
		if (date && endDate && date > endDate) {
			// If selected start date is after end date, don't allow it
			return;
		}
		onStartDateChange(date);
	};

	return (
		<div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
			<div className="flex-1">
				<label className="block text-sm font-medium text-gray-700 mb-1">Due Date*</label>
				<div className="relative">
					<DatePicker
						selected={endDate}
						onChange={handleEndDateChange}
						selectsEnd
						startDate={convertNullToUndefined(startDate)}
						endDate={convertNullToUndefined(endDate)}
						minDate={minEndDate || minDate}
						maxDate={maxDate}
						disabled={disabled}
						className="mt-1 block w-full rounded-md border border-[#00a9af] pl-10 pr-3 py-2 text-gray-900"
						placeholderText="Select due date"
						excludeDates={[]} // You can add specific dates to exclude if needed
						filterDate={(date) => {
							if (minEndDate && date < minEndDate) return false;
							if (maxDate && date > maxDate) return false;
							return true;
						}}
					/>
					<CalendarIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
				</div>
			</div>
			<div className="flex-1">
				<label className="block text-sm font-medium text-gray-700 mb-1">Start of Availability Window*</label>
				<div className="relative">
					<DatePicker
						selected={startDate}
						onChange={handleStartDateChange}
						selectsStart
						startDate={convertNullToUndefined(startDate)}
						endDate={convertNullToUndefined(endDate)}
						maxDate={endDate || undefined}
						minDate={minDate}
						disabled={disabled}
						className="mt-1 block w-full rounded-md border border-[#00a9af] pl-10 pr-3 py-2 text-gray-900"
						placeholderText="Select start date"
					/>
					<CalendarIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
				</div>
			</div>
		</div>
	);
};

const SharedDatePicker: React.FC<{
	isEditMode: boolean;
	startDate: Date | null;
	dueDate: Date | null;
	onStartDateChange: (date: Date | null) => void;
	onDueDateChange: (date: Date | null) => void;
	disabled?: boolean;
	useSharedDates: boolean;
	onToggleSharedDates: (checked: boolean, setFieldValue: (field: string, value: any) => void) => void;
	setFieldValue: (field: string, value: any) => void;
}> = ({ isEditMode, startDate, dueDate, onStartDateChange, onDueDateChange, disabled, useSharedDates, onToggleSharedDates, setFieldValue }) => {
	return (
		<>
			<div className={`${!useSharedDates ? "opacity-50" : ""} bg-white border border-[#eaecf0] rounded-lg p-6 mb-6`}>
				<div className="flex items-center">
					{!isEditMode ? (
						<>
							<Switch checked={useSharedDates} onCheckedChange={(checked) => onToggleSharedDates(checked, setFieldValue)} />
							<h3 className="text-lg font-medium text-gray-900 ml-3">Shared Schedule</h3>
						</>
					) : (
						<p className="text-sm text-gray-500">Schedule settings are locked in edit mode</p>
					)}
				</div>
				{!isEditMode && useSharedDates && (
					<>
						<p className="text-sm text-gray-600 my-4">
							Use one schedule for all interview stages. Toggle this off if you need to set different dates for each stage.
						</p>
						<DateRangePicker
							startDate={startDate}
							endDate={dueDate}
							onStartDateChange={onStartDateChange}
							onEndDateChange={onDueDateChange}
							disabled={disabled}
							minDate={new Date()}
						/>
					</>
				)}
			</div>
			{!useSharedDates && (
				<p className="text-sm text-gray-600 italic">
					Individual date selection is now enabled. Please set specific dates for each active interview stage above.
				</p>
			)}
		</>
	);
};

// Add this helper function before the InterviewDates component
const getSessionIndexForStage = (stages: InterviewStage[], stageIndex: number): number => {
	return stages.slice(0, stageIndex).filter((stage) => stage.enabled).length;
};

// InterviewDates component for configuring reflection sessions
const InterviewDates: React.FC<InterviewDatesProps> = ({
	values,
	errors,
	touched,
	setFieldValue,
	onNext,
	onPrevious,
	isValid,
	dirty,
	isEditMode
}) => {
	const isFormValid = () => {
		if (isEditMode) return true;

		// First check if any stages are enabled
		const hasEnabledStages = values.stages?.some((stage) => stage.enabled);
		if (!hasEnabledStages) return false;

		// If using shared dates, check if both shared dates are set
		if (values.useSharedDates) {
			return values.sharedStartDate !== null && values.sharedDueDate !== null;
		}

		// If not using shared dates, check each enabled stage has both dates set
		return values.stages?.every((stage, index) => {
			if (!stage.enabled) return true; // Skip disabled stages
			const session = values.sessions[index];
			return session?.startDate !== null && session?.date !== null;
		});
	};

	// Initialize stages if not already set
	useEffect(() => {
		if (!values.stages) {
			let initialStages: InterviewStage[] = [];

			if (isEditMode && values.sessions?.length > 0) {
				// Process existing sessions to create stages
				values.sessions.forEach((session, index) => {
					let stageType: InterviewStageType;
					let orderNumber: number;

					if (session.interview) {
						// If we have interview data, use it directly
						stageType = session.interview.interviewStageType;
						orderNumber = session.interview.interviewStageOrderNumber;
					} else {
						// Fallback to title-based logic
						if (session.title.toLowerCase().includes("screening")) {
							stageType = "screening";
							orderNumber = 1;
						} else if (session.title.toLowerCase().includes("final")) {
							stageType = "final";
							orderNumber = values.sessions.length;
						} else {
							stageType = "middle";
							orderNumber = index + 1;
						}
					}

					initialStages.push({
						interviewStageOrderNumber: orderNumber,
						interviewStageType: stageType,
						enabled: true,
						title: session.title,
						description: `${session.title} stage for candidate evaluation`,
						date: session.date,
						startDate: session.startDate
					});
				});

				// Sort stages by order number (null values - resume review - should come first)
				initialStages.sort((a, b) => {
					if (a.interviewStageOrderNumber === null) return -1;
					if (b.interviewStageOrderNumber === null) return 1;
					return a.interviewStageOrderNumber - b.interviewStageOrderNumber;
				});
			} else {
				// Default stages for create mode
				initialStages = [
					{
						interviewStageOrderNumber: 1,
						interviewStageType: "screening",
						enabled: true,
						title: "Screening Interview",
						description: "Initial screening interview with candidates",
						date: null,
						startDate: null
					},
					{
						interviewStageOrderNumber: 2,
						interviewStageType: "middle",
						enabled: true,
						title: "Middle Interview",
						description: "Additional interview stage for candidate evaluation",
						date: null,
						startDate: null
					},
					{
						interviewStageOrderNumber: 3,
						interviewStageType: "final",
						enabled: true,
						title: "Final Interview",
						description: "Final interview stage for candidate evaluation",
						date: null,
						startDate: null
					}
				];
			}
			setFieldValue("stages", initialStages);

			// Initialize sessions to match only enabled stages
			setFieldValue(
				"sessions",
				initialStages.filter((stage) => stage.enabled) // Only include enabled stages
			);

			// Initialize shared dates - now defaulting to true
			if (values.sharedStartDate === undefined) {
				setFieldValue("sharedStartDate", null);
			}
			if (values.sharedDueDate === undefined) {
				setFieldValue("sharedDueDate", null);
			}
			if (values.useSharedDates === undefined) {
				setFieldValue("useSharedDates", true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleStageToggle = (stageType: InterviewStageType, orderNumber: number, enabled: boolean) => {
		const updatedStages = values.stages.map((stage) => {
			if (stage.interviewStageType === stageType && stage.interviewStageOrderNumber === orderNumber) {
				return { ...stage, enabled };
			}
			return stage;
		});

		// If enabling/disabling a middle stage, reset final interview dates
		if (stageType === "middle") {
			const updatedSessions = [...values.sessions];
			const finalIndex = values.stages.findIndex((s) => s.interviewStageType === "final");

			// Reset final interview dates
			if (finalIndex !== -1) {
				updatedSessions[finalIndex] = {
					...updatedSessions[finalIndex],
					date: null,
					startDate: null
				};
			}

			// If disabling a middle stage
			if (!enabled) {
				// Disable this stage and all subsequent middle stages
				const finalStages = updatedStages.map((stage) => {
					if (stage.interviewStageType === "middle" && stage.interviewStageOrderNumber >= orderNumber) {
						return { ...stage, enabled: false };
					}
					return stage;
				});
				setFieldValue("stages", finalStages);
				setFieldValue("sessions", updatedSessions);
				return;
			}

			// If enabling a middle stage, just enable that specific stage
			setFieldValue("stages", updatedStages);
			setFieldValue("sessions", updatedSessions);
			return;
		}

		setFieldValue("stages", updatedStages);
	};

	const addMiddleStage = () => {
		const middleStages = values.stages.filter((s) => s.interviewStageType === "middle");
		if (middleStages.length >= 3) return;

		// Find the last enabled middle stage's order number
		const lastMiddleStage = [...middleStages].sort((a, b) => b.interviewStageOrderNumber - a.interviewStageOrderNumber)[0];

		const newStage: InterviewStage = {
			interviewStageOrderNumber: lastMiddleStage ? lastMiddleStage.interviewStageOrderNumber + 1 : 1,
			interviewStageType: "middle",
			enabled: true,
			title: `Middle Interview ${middleStages.length > 0 ? (middleStages.length === 1 ? "Two" : "Three") : ""}`,
			description: "Additional interview stage for candidate evaluation",
			date: null,
			startDate: null
		};

		const finalIndex = values.stages.findIndex((s) => s.interviewStageType === "final");

		// Create new stages array with updated order numbers
		const newStages = values.stages.map((stage, index) => {
			if (index < finalIndex) {
				// Keep stages before final as is
				return stage;
			} else if (stage.interviewStageType === "final" && stage.enabled) {
				// Increment final stage order number if it's enabled
				return {
					...stage,
					interviewStageOrderNumber: stage.interviewStageOrderNumber + 1
				};
			}
			return stage;
		});

		// Insert the new middle stage
		newStages.splice(finalIndex, 0, newStage);

		setFieldValue("stages", newStages);

		// Add corresponding session
		const newSession = {
			title: newStage.title,
			date: null,
			startDate: null
		};
		const updatedSessions = [...values.sessions.slice(0, finalIndex), newSession, ...values.sessions.slice(finalIndex)];

		// Reset final interview dates
		if (finalIndex !== -1) {
			updatedSessions[finalIndex + 1] = {
				// Note the +1 here because we just inserted a new session
				...updatedSessions[finalIndex],
				date: null,
				startDate: null
			};
		}
		setFieldValue("sessions", updatedSessions);
	};

	const getPreviousStageEndDate = (stages: InterviewStage[], sessions: Session[], currentIndex: number): Date | undefined => {
		// Find the previous enabled stage
		for (let i = currentIndex - 1; i >= 0; i--) {
			if (stages[i].enabled && sessions[i]?.date) {
				return sessions[i].date || undefined;
			}
		}
		return undefined;
	};

	// Add this function to handle shared date changes
	const handleSharedDateChange = (type: "start" | "due", date: Date | null) => {
		if (type === "start") {
			setFieldValue("sharedStartDate", date);
		} else {
			setFieldValue("sharedDueDate", date);
		}

		// Update all enabled stages with the shared dates
		const updatedSessions = values.sessions.map((session: any, index: number) => {
			// Only update if the corresponding stage is enabled
			if (values.stages[index]?.enabled) {
				return {
					...session,
					title: values.stages[index].title,
					startDate: type === "start" ? date : values.sharedStartDate,
					date: type === "due" ? date : values.sharedDueDate
				};
			}
			return session;
		});

		setFieldValue("sessions", updatedSessions);
	};

	// Add this helper function to get the active stage number
	const getActiveStageNumber = (currentIndex: number): number => {
		return values.stages.slice(0, currentIndex + 1).filter((stage) => stage.enabled).length;
	};

	const handleToggleSharedDates = (checked: boolean, setFieldValue: (field: string, value: any) => void) => {
		setFieldValue("useSharedDates", checked);

		if (checked) {
			// When enabling shared dates, apply the shared dates to all enabled stages
			const updatedSessions = values.sessions.map((session: any, index: number) => {
				if (values.stages[index]?.enabled) {
					return {
						...session,
						title: values.stages[index].title,
						startDate: values.sharedStartDate,
						date: values.sharedDueDate
					};
				}
				return session;
			});
			setFieldValue("sessions", updatedSessions);
		} else {
			// When disabling shared dates, reset all dates
			const resetSessions = values.sessions.map((session: any, index: number) => ({
				...session,
				title: values.stages[index]?.title || session.title,
				startDate: null,
				date: null
			}));
			setFieldValue("sessions", resetSessions);
			setFieldValue("sharedStartDate", null);
			setFieldValue("sharedDueDate", null);
		}
	};

	// Update the useEffect that handles stage changes
	useEffect(() => {
		if (values.stages) {
			// Create or update sessions to match stages
			const updatedSessions = values.stages
				.filter((stage) => stage.enabled) // Only include enabled stages
				.map((stage: any, index: number) => {
					const existingSession = values.sessions?.[index] || {};
					return {
						...existingSession,
						title: stage.title,
						startDate: values.useSharedDates ? values.sharedStartDate : existingSession.startDate,
						date: values.useSharedDates ? values.sharedDueDate : existingSession.date
					};
				});
			setFieldValue("sessions", updatedSessions);
		}
	}, [values.stages, values.useSharedDates, values.sharedStartDate, values.sharedDueDate]);

	const validateStagesSection = (stages: InterviewStage[], sessions: Session[], useSharedDates: boolean): "valid" | "invalid" => {
		// First check if any stages are enabled
		const hasEnabledStages = stages?.some((stage) => stage.enabled);
		if (!hasEnabledStages) return "invalid";

		// If using shared dates, we only care that at least one stage is enabled
		if (useSharedDates) return "valid";

		// When not using shared dates, check if all enabled stages have their dates set
		const allEnabledStagesHaveDates = stages.every((stage, index) => {
			if (!stage.enabled) return true; // Skip disabled stages
			const session = sessions[index];
			return session?.startDate !== null && session?.date !== null;
		});

		return allEnabledStagesHaveDates ? "valid" : "invalid";
	};

	const validateScheduleSection = (
		useSharedDates: boolean,
		sharedStartDate: Date | null,
		sharedDueDate: Date | null
	): "valid" | "invalid" | "none" => {
		if (!useSharedDates) return "none";
		return sharedStartDate && sharedDueDate ? "valid" : "invalid";
	};

	return (
		<div className="space-y-8">
			<h2 className="text-2xl font-semibold mb-6">Interview Dates</h2>
			{/* Instructions Panel */}
			{/* <Panel title="Configure Interview Stages & Schedule">
				<div className="space-y-4">
					<div>
						<p className="text-base font-medium mb-2">Step 1: Select Interview Stages</p>
						<ul className="ml-8 text-sm space-y-2 list-disc">
							<li>By default, there are three stages: Screening, Middle, and Final interviews</li>
							<li>You can add up to two additional middle interview stages if needed</li>
							<li>Toggle stages on/off to customize the assignment process</li>
						</ul>
					</div>
					<div className="mt-4">
						<p className="text-base font-medium mb-2">Step 2: Set Interview Schedule</p>
						<ul className="ml-8 text-sm space-y-2 list-disc">
							<li>For each interview stage, you'll need to set:</li>
							<ul className="ml-8 mt-2 space-y-2 list-disc">
								<li>Due Date: The deadline by which the interview must be completed</li>
								<li>Start Date: When students can begin scheduling their interview</li>
							</ul>
							<li>You have two options for setting these dates:</li>
							<ul className="ml-8 mt-2 space-y-2 list-disc">
								<li>Option 1: Use Shared Schedule (default) - Set one timeline that applies to all stages</li>
								<li>Option 2: Individual Schedules - Toggle off Shared Schedule to set unique dates for each stage</li>
							</ul>
						</ul>
					</div>
				</div>
			</Panel> */}

			{/* Step 1: Interview Stages Section */}
			<Panel
				title="Step 1: Select Interview Stages"
				validationState={validateStagesSection(values.stages, values.sessions, values.useSharedDates)}
			>
				<p className="text-sm text-gray-600 mb-8">
					Toggle stages on/off using the switches next to each stage. Add up to two additional middle interview stages if needed.
				</p>
				<FieldArray name="sessions">
					{({ push, remove }) => (
						<div className="space-y-4">
							{values.stages?.map((stage, index) => {
								// Skip rendering disabled middle stages based on previous middle stages
								if (stage.interviewStageType === "middle") {
									const middleStages = values.stages.filter((s) => s.interviewStageType === "middle");
									const middleIndex = middleStages.findIndex(
										(s) => s.interviewStageOrderNumber === stage.interviewStageOrderNumber
									);

									// Skip middle-2 if middle-1 is disabled
									if (middleIndex === 1 && !middleStages[0].enabled) {
										return null;
									}
									// Skip middle-3 if either middle-1 or middle-2 is disabled
									if (middleIndex === 2 && (!middleStages[0].enabled || !middleStages[1].enabled)) {
										return null;
									}
								}

								// Render the Add Interview button before the final stage
								const isFinalStage = stage.interviewStageType === "final";
								const middleStages = values.stages.filter((s) => s.interviewStageType === "middle");
								const enabledMiddleStages = middleStages.filter((s) => s.enabled);

								// Show Add Interview button if:
								// - We're at the final stage
								// - At least one middle stage is enabled
								// - We have less than 3 middle stages OR
								// - The last middle stage is disabled (meaning we can replace it)
								const showAddMiddleButton =
									!isEditMode &&
									isFinalStage &&
									enabledMiddleStages.length > 0 &&
									(middleStages.length < 3 || !middleStages[middleStages.length - 1].enabled);

								return (
									<React.Fragment key={stage.interviewStageOrderNumber}>
										{showAddMiddleButton && (
											<Button
												variant="outline"
												onClick={addMiddleStage}
												className="w-full text-primary-900 border-primary-900 bg-white"
											>
												<PlusIcon className="h-5 w-5 mr-2 text-primary-900" />
												<span className="text-primary-900">Add Interview</span>
											</Button>
										)}
										<div className={`bg-white border border-[#eaecf0] rounded-lg p-6 ${!stage.enabled ? "opacity-50" : ""}`}>
											<div className="flex items-center">
												<Switch
													checked={stage.enabled}
													onCheckedChange={(checked) =>
														handleStageToggle(stage.interviewStageType, stage.interviewStageOrderNumber, checked)
													}
													disabled={isEditMode}
												/>
												<h3 className="text-lg font-medium text-gray-900 ml-3">
													{stage.enabled && `${getActiveStageNumber(index)}. `}
													{stage.title}
												</h3>
												<CustomTooltip content={stage.description} />
											</div>

											{stage.enabled && !values.useSharedDates && (
												<div className="mt-4">
													<DateRangePicker
														startDate={values.sessions[getSessionIndexForStage(values.stages, index)]?.startDate || null}
														endDate={values.sessions[getSessionIndexForStage(values.stages, index)]?.date || null}
														onStartDateChange={(date) =>
															setFieldValue(
																`sessions[${getSessionIndexForStage(values.stages, index)}].startDate`,
																date
															)
														}
														onEndDateChange={(date) =>
															setFieldValue(`sessions[${getSessionIndexForStage(values.stages, index)}].date`, date)
														}
														disabled={values.isPublished}
														minDate={new Date()}
														maxDate={
															values.sessions[getSessionIndexForStage(values.stages, index) + 1]?.date || undefined
														}
														minEndDate={getPreviousStageEndDate(values.stages, values.sessions, index)}
													/>
												</div>
											)}
										</div>
									</React.Fragment>
								);
							})}
						</div>
					)}
				</FieldArray>
			</Panel>

			{/* Step 2: Schedule Section */}
			<Panel
				title="Step 2: Set Interview Schedule"
				validationState={validateScheduleSection(values.useSharedDates, values.sharedStartDate, values.sharedDueDate)}
			>
				<SharedDatePicker
					isEditMode={isEditMode}
					startDate={values.sharedStartDate}
					dueDate={values.sharedDueDate}
					onStartDateChange={(date) => handleSharedDateChange("start", date)}
					onDueDateChange={(date) => handleSharedDateChange("due", date)}
					disabled={values.isPublished}
					useSharedDates={values.useSharedDates}
					onToggleSharedDates={handleToggleSharedDates}
					setFieldValue={setFieldValue}
				/>
			</Panel>

			{/* Navigation buttons - visible only on larger screens */}
			<div className="hidden md:flex justify-end mt-8 gap-2">
				<Button
					onClick={() => {
						onNext();
					}}
					disabled={!isFormValid()}
				>
					Next
				</Button>
			</div>
		</div>
	);
};

// Panel component for grouping related form fields
const Panel: React.FC<{
	title: string;
	children: React.ReactNode;
	validationState?: "valid" | "invalid" | "none";
}> = ({ title, children, validationState = "none" }) => (
	<div
		className={`bg-white border rounded-lg p-6 ${
			validationState === "valid" ? "border-[#00a9af]" : validationState === "invalid" ? "border-incomplete" : "border-[#eaecf0]"
		}`}
	>
		<div className="flex justify-between items-center mb-4">
			<h3 className="text-lg font-semibold text-gray-800">{title}</h3>
			{validationState === "valid" && <CheckCircleIcon className="w-6 h-6 text-[#00a9af]" />}
			{validationState === "invalid" && <CheckCircleOutlineIcon className="w-6 h-6 text-incomplete" />}
		</div>
		<div className="space-y-4">{children}</div>
	</div>
);

export default InterviewDates;
