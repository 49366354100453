import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Field, ErrorMessage } from "formik";
import { Button } from "../../../shadcn-ui/Button";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "../../../shadcn-ui/Select";
import InterviewPlan from "./InterviewPlan";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon as CheckCircleOutlineIcon } from "@heroicons/react/24/outline";
import CustomTooltip from "../../../Utility/CustomTooltip";
import Spinner from "../../../Utility/Spinner";
import JobTitleSelect from "./JobTitleSelect";
import JobPostings from "./JobPostings";
import { AssignmentService, JobPosting } from "../../../../services/AssignmentService";
import { InterviewPayload, InterviewStage, InterviewStageType, InterviewStagePlan } from "../../../../types/InterviewPayload";

// Define props for the InterviewSetup component
export interface InterviewSetupProps {
	values: {
		stages?: InterviewStage[];
		stagePlan: {
			[key: string]: InterviewStagePlan;
		};
		[key: string]: any;
	};
	errors: any;
	touched: any;
	setFieldValue: (field: string, value: any) => void;
	onPrevious: () => void;
	onNext: () => void;
	isValid: boolean;
	dirty: boolean;
	isEditMode: boolean;
}

// Helper function to determine stage type based on order
export const determineStageType = (orderNumber: number): InterviewStageType => {
	switch (orderNumber) {
		case 0:
			return "resume";
		case 1:
			return "screening";
		case 2:
			return "middle";
		case 3:
			return "final";
		default:
			return orderNumber > 3 ? "final" : "middle";
	}
};

// Helper function to get stage title based on type and order
export const getStageTitle = (type: InterviewStageType, orderNumber: number): string => {
	switch (type) {
		case "resume":
			return "Resume Review";
		case "screening":
			return "Screening Interview";
		case "middle":
			return `Middle Interview ${orderNumber > 2 ? orderNumber - 1 : ""}`;
		case "final":
			return "Final Interview";
	}
};

// Update the helper function
const isInterviewSetupValid = (values: any) => {
	if (values.jobTitleChoice === "Dynamic") return true;

	// Check if both fields exist and have non-empty trimmed values
	const hasTitle = Boolean(values.staticJobTitle?.trim());
	const hasDescription = Boolean(values.jobDescription?.trim());

	return hasTitle && hasDescription;
};

// InterviewSetup component for capturing basic assignment details
const InterviewSetup: React.FC<InterviewSetupProps> = ({
	values,
	errors,
	touched,
	setFieldValue,
	onPrevious,
	onNext,
	isValid,
	dirty,
	isEditMode
}) => {
	const location = useLocation();
	const isAdminPath = location.pathname.startsWith("/admin");

	const [jobPostings, setJobPostings] = useState<JobPosting[]>([]);
	const [isLoadingJobPosting, setIsLoadingJobPosting] = useState(false);

	// TODO: remove this useEffect (just for testing while currentStep is 1)
	// // Initialize stages if not already set
	// useEffect(() => {
	// 	if (!values.stages) {
	// 		const initialStages = [
	// 			{
	// 				id: "resume",
	// 				type: "resume",
	// 				enabled: false,
	// 				title: "Resume Review",
	// 				description: "Initial resume preparation stage"
	// 			},
	// 			{
	// 				id: "screening",
	// 				type: "screening",
	// 				enabled: true,
	// 				title: "Screening Interview",
	// 				description: "Initial screening interview with candidates"
	// 			},
	// 			{
	// 				id: "middle-1",
	// 				type: "middle",
	// 				enabled: true,
	// 				title: "Middle Interview",
	// 				description: "Additional interview stage for candidate evaluation"
	// 			},
	// 			{
	// 				id: "final",
	// 				type: "final",
	// 				enabled: true,
	// 				title: "Final Interview",
	// 				description: "Final interview stage for candidate evaluation"
	// 			}
	// 		];
	// 		setFieldValue("stages", initialStages);

	// 		// Initialize sessions to match stages
	// 		setFieldValue(
	// 			"sessions",
	// 			initialStages.map((stage) => ({
	// 				title: stage.title,
	// 				date: null,
	// 				startDate: null
	// 			}))
	// 		);
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	// Initialize jobTitleChoice based on faculty clearance
	useEffect(() => {
		if (!values.jobTitleChoice) {
			// If not faculty, always set to Static
			setFieldValue("jobTitleChoice", isAdminPath ? "Dynamic" : "Static");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Check if the form is valid
	const isFormValid = () => {
		if (isEditMode) return true;

		// Check staticJobTitle only when jobTitleChoice is Static
		const hasValidJobTitle = values.jobTitleChoice === "Static" ? values.staticJobTitle && values.staticJobTitle.trim() !== "" : true;

		// Check staticJobDescription only when jobTitleChoice is Static
		const hasValidJobDescription = values.jobTitleChoice === "Static" ? values.jobDescription && values.jobDescription.trim() !== "" : true;

		// Check if each enabled stage has at least one question
		const hasValidStageQuestions =
			values.stages?.every((stage: InterviewStage) => {
				// Skip check for disabled stages and resume review
				if (!stage.enabled || stage.interviewStageType === "resume") return true;

				const stagePlan = values.stagePlan?.[stage.interviewStageOrderNumber];
				if (!stagePlan) return false;

				// Calculate total questions for this stage
				const totalQuestions =
					(stagePlan.technicalQuestions || 0) +
					(stagePlan.behavioralQuestions || 0) +
					(stagePlan.situationalQuestions || 0) +
					(stagePlan.culturalFitQuestions || 0) +
					(stagePlan.backgroundQuestions || 0) +
					(stagePlan.motivationQuestions || 0) +
					(stagePlan.generalQuestions || 0);

				return totalQuestions > 0;
			}) ?? false;

		return hasValidJobTitle && hasValidJobDescription && hasValidStageQuestions;
	};

	const searchJobs = async (title: string) => {
		setJobPostings([]);
		try {
			const jobPostingList = await AssignmentService.searchJobPostings(title);
			setJobPostings(jobPostingList);
		} catch (error) {
			console.error("Error fetching job postings:", error);
			setJobPostings([]);
		}
	};

	const handleJobSelection = async (option: JobPosting) => {
		setFieldValue("staticJobTitle", option.title);

		// Create interview payload
		const interviewData: Partial<InterviewPayload> = {
			jobTitle: option.title,
			jobSummary: option.summary || "",
			jobFunction: "", // These can be added as form fields if needed
			jobExperienceLevel: "",
			jobResponsibilities: [],
			jobSkillsRequired: []
		};

		if (option.postingId === "custom") {
			setFieldValue("jobDescription", option.summary || "");
			interviewData.jobDescription = option.summary || "";
		} else {
			try {
				setIsLoadingJobPosting(true);
				// Make API call to get full job details
				const fullJobDetails = await AssignmentService.getJobPosting(option.postingId);
				const description = htmlToPlainText(fullJobDetails.jobBody || "") || option.summary || "";
				setFieldValue("jobDescription", description);
				interviewData.jobDescription = description;
			} catch (error) {
				console.error("Error fetching full job details:", error);
				setFieldValue("jobDescription", option.summary || "");
				interviewData.jobDescription = option.summary || "";
			} finally {
				setIsLoadingJobPosting(false);
			}
		}

		// Update the interview payload in the form
		setFieldValue("interview", new InterviewPayload(interviewData));
	};

	const htmlToPlainText = (html: string) => {
		const tempElement = document.createElement("div");
		tempElement.innerHTML = html;

		const text = tempElement.innerHTML
			.replace(/&nbsp;/g, " ") // Replace &nbsp; with a space
			.replace(/<\/p>/g, "\n\n") // Add new lines after each paragraph
			.replace(/<\/li>/g, "\n") // Add new lines after each list item
			.replace(/<li>/g, "- ") // Convert list items to dashes
			.replace(/<br>/g, "\n") // Convert line breaks to new lines
			.replace(/<strong>/g, "*") // Convert opening strong tags to asterisks
			.replace(/<\/strong>/g, "*") // Convert closing strong tags to asterisks
			.replace(/\n\s*\n/g, "\n\n") // Replace multiple newlines with two newlines
			.replace(/&amp;/g, "&") // Replace &amp; with &
			.replace(/<\/?[^>]+(>|$)/g, ""); // Final step - Remove remaining HTML tags
		return text.trim();
	};

	return (
		<div className="space-y-8">
			<h2 className="text-2xl font-semibold mb-6">Interview Setup</h2>
			<Panel title="Interview Setup" validationStatus={isInterviewSetupValid(values)}>
				{/* Students Choose Job Title Field - only shown for faculty */}
				{isAdminPath && (
					<div className="mt-4">
						<label htmlFor="jobTitleChoice" className="text-sm font-medium text-gray-700 mb-1 flex items-center">
							Students Choose Job Title *
							<CustomTooltip content="If 'Yes', students will be able to input their desired job title" />
						</label>
						<div className="relative">
							<Select
								defaultValue="Dynamic"
								value={values.jobTitleChoice}
								onValueChange={(value) => {
									setFieldValue("jobTitleChoice", value);
									if (value === "Dynamic") {
										setFieldValue("staticJobTitle", "");
										setFieldValue("jobDescription", "");
									}
								}}
								disabled={isEditMode}
							>
								<SelectTrigger className="w-full px-4 py-2 text-left bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
									<SelectValue placeholder="Students Choose Job Title" />
								</SelectTrigger>
								<SelectContent className="w-full bg-white border border-gray-300 rounded-md shadow-lg">
									<SelectItem value="Dynamic" className="py-2 px-4 hover:bg-gray-100">
										Yes
									</SelectItem>
									<SelectItem value="Static" className="py-2 px-4 hover:bg-gray-100">
										No
									</SelectItem>
								</SelectContent>
							</Select>
						</div>
					</div>
				)}

				{/* Static Job Title Field - shown when jobTitleChoice is Static or user is not faculty */}
				{(values.jobTitleChoice === "Static" || !isAdminPath) && (
					<div className="mt-4 space-y-4">
						<div>
							<label htmlFor="staticJobTitle" className="block text-sm font-medium text-gray-700 mb-1">
								Job Title *
							</label>
							<JobTitleSelect
								value={values.staticJobTitle || ""}
								onChange={(value) => setFieldValue("staticJobTitle", value)}
								onJobSearch={searchJobs}
								disabled={isEditMode}
							/>
							<ErrorMessage name="staticJobTitle" component="div" className="mt-1 text-sm text-red-600" />
							<JobPostings jobTitle={values.staticJobTitle} options={jobPostings} onSelect={handleJobSelection} />
						</div>

						<div>
							<label htmlFor="jobDescription" className="block text-sm font-medium text-gray-700 mb-1">
								Job Description *
							</label>
							{isLoadingJobPosting ? (
								<Spinner />
							) : (
								<Field
									as="textarea"
									name="jobDescription"
									id="jobDescription"
									rows={8}
									className={`mt-1 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 text-base p-2 ${
										isEditMode ? "text-gray-500" : "text-gray-900"
									}`}
									placeholder="Enter the job description..."
									disabled={isEditMode}
								/>
							)}
							<ErrorMessage name="jobDescription" component="div" className="mt-1 text-sm text-red-600" />
						</div>
					</div>
				)}
			</Panel>

			{/* Interview Plans for each enabled stage, excluding resume review */}
			{values.stages
				?.filter((stage: InterviewStage) => stage.enabled && stage.interviewStageType !== "resume")
				.map((stage: InterviewStage, index: number) => {
					// Calculate total questions for this stage
					const stagePlan = values.stagePlan?.[stage.interviewStageOrderNumber];
					const totalQuestions = stagePlan
						? (stagePlan.technicalQuestions || 0) +
						  (stagePlan.behavioralQuestions || 0) +
						  (stagePlan.situationalQuestions || 0) +
						  (stagePlan.culturalFitQuestions || 0) +
						  (stagePlan.generalQuestions || 0)
						: 0;

					return (
						<Panel key={stage.interviewStageOrderNumber} title={`Interview Plan - ${stage.title}`} totalQuestions={totalQuestions}>
							<InterviewPlan
								interviewStageOrderNumber={stage.interviewStageOrderNumber}
								values={values}
								setFieldValue={setFieldValue}
								isAdminPath={isAdminPath}
							/>
						</Panel>
					);
				})}

			{/* Navigation buttons - visible only on larger screens */}
			<div className="hidden md:flex justify-end mt-8 gap-2">
				<Button
					type="button"
					variant="secondary"
					onClick={() => {
						onPrevious();
					}}
				>
					Previous
				</Button>
				<Button
					onClick={() => {
						onNext();
					}}
					disabled={!isFormValid()}
				>
					Next
				</Button>
			</div>
		</div>
	);
};

// Panel component for grouping related form fields
const Panel: React.FC<{
	title: string;
	children: React.ReactNode;
	totalQuestions?: number;
	validationStatus?: boolean;
}> = ({ title, children, totalQuestions, validationStatus }) => {
	const getBorderAndIcon = () => {
		if (validationStatus !== undefined) {
			return validationStatus
				? {
						border: "border-[#00a9af]",
						icon: <CheckCircleIcon className="w-6 h-6 text-[#00a9af]" />
				  }
				: {
						border: "border-incomplete",
						icon: <CheckCircleOutlineIcon className="w-6 h-6 text-incomplete" />
				  };
		}
		if (totalQuestions === undefined) {
			return { border: "border-[#eaecf0]", icon: null };
		}
		return totalQuestions > 0
			? {
					border: "border-[#00a9af]",
					icon: <CheckCircleIcon className="w-6 h-6 text-[#00a9af]" />
			  }
			: {
					border: "border-incomplete",
					icon: <CheckCircleOutlineIcon className="w-6 h-6 text-incomplete" />
			  };
	};

	const { border, icon } = getBorderAndIcon();

	return (
		<div className={`bg-white border rounded-lg p-6 ${border}`}>
			<div className="flex justify-between items-center mb-4">
				<h3 className="text-lg font-semibold text-gray-800">{title}</h3>
				{icon}
			</div>
			<div className="space-y-4">{children}</div>
		</div>
	);
};

export default InterviewSetup;
