import { useState } from "react";
import { CheckCircleIcon, PlusCircleIcon, ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { ChartBarIcon } from "@heroicons/react/24/solid";
import ReportScore from "./ReportScore";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../shadcn-ui/Dialog";
import { Button } from "../shadcn-ui/Button";
import { Textarea } from "../shadcn-ui/Textarea";
// import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../shadcn-ui/Table";
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts";
import { Card, CardContent, CardHeader, CardTitle } from "../shadcn-ui/Card";
import { ChartConfig, ChartContainer, ChartTooltip } from "../shadcn-ui/Chart";

export interface ReportScoreDataPoint {
	date: Date;
	value: number;
	scheduleDescription?: string;
}

interface ReportCardProps {
	title: string;
	icon?: React.ReactNode;
	children: React.ReactNode;
	reportScoreData?: number[];
	showReportScore?: boolean;
	lineColor?: string;
	currentScore?: number;
	onConfirm?: (confirmed: boolean) => void;
	confirmed?: boolean;
	initialComment?: string;
	onAddComment?: (content: string, title: string) => void;
	canComment?: boolean;
	hideButtons?: boolean;
	reportScoreDetails?: ReportScoreDataPoint[];
	showDataTable?: boolean;
	isAdminView?: boolean;
}

const ReportCard: React.FC<ReportCardProps> = ({
	title,
	icon,
	children,
	reportScoreData,
	showReportScore = true,
	lineColor = "#d4d4d8",
	currentScore,
	onConfirm,
	confirmed = false,
	onAddComment,
	initialComment = "",
	canComment = false,
	hideButtons = false,
	reportScoreDetails,
	showDataTable = false,
	isAdminView = false
}) => {
	const [isCommentDialogOpen, setIsCommentDialogOpen] = useState(false);
	const [comment, setComment] = useState(initialComment);
	const [isCommented, setIsCommented] = useState(initialComment !== "");
	const [showProgressionChart, setShowProgressionChart] = useState(false);

	const handleAddComment = () => {
		if (comment.trim()) {
			onAddComment?.(comment, title);
			setIsCommented(true);
			setIsCommentDialogOpen(false);
		}
	};

	const showCommentButton = hideButtons ? false : canComment || isCommented;

	const renderProgressionChart = () => {
		if (!reportScoreDetails || reportScoreDetails.length < 2) return null;

		const chartData = reportScoreDetails.map((point) => ({
			date: new Date(point.date).toLocaleDateString("en-US", {
				month: "short",
				day: "numeric"
			}),
			value: point.value,
			description: point.scheduleDescription
		}));

		const chartConfig: ChartConfig = {
			value: {
				label: title,
				color: "#00a9af"
			}
		};

		return (
			<Card className="mb-4">
				<CardHeader className="pb-2">
					<CardTitle className="text-sm font-medium">Progression Over Time</CardTitle>
				</CardHeader>
				<CardContent className="h-80">
					<ChartContainer config={chartConfig} className="h-full w-full">
						<LineChart
							data={chartData}
							margin={{
								top: 10,
								right: 10,
								left: 10,
								bottom: 10
							}}
						>
							<CartesianGrid vertical={false} strokeDasharray="3 3" />
							<XAxis dataKey="date" tickLine={false} axisLine={false} tickMargin={4} fontSize={11} padding={{ left: 20, right: 40 }} />
							<YAxis
								tickLine={false}
								axisLine={false}
								tickMargin={4}
								domain={[0, 10]}
								fontSize={11}
								padding={{ top: 20, bottom: 20 }}
								ticks={[0, 2, 4, 6, 8, 10]}
							/>
							<ChartTooltip
								cursor={false}
								content={({ active, payload }) => {
									if (!active || !payload?.length) return null;
									return (
										<div className="rounded-lg border bg-background p-2 shadow-sm">
											<div className="flex flex-col gap-1">
												<span className="text-[0.70rem] uppercase text-muted-foreground">
													{payload[0].payload.description}
												</span>
												<span className="font-bold text-muted-foreground">Score: {payload[0].value}</span>
											</div>
										</div>
									);
								}}
							/>
							<Line
								type="monotone"
								dataKey="value"
								stroke="#00a9af"
								strokeWidth={2}
								dot={{
									fill: "#00a9af",
									r: 3
								}}
								activeDot={{
									r: 4,
									fill: "#00a9af"
								}}
							/>
						</LineChart>
					</ChartContainer>
				</CardContent>
			</Card>
		);
	};

	return (
		<div className="relative bg-white border border-[#eaecf0] rounded-lg p-4 mb-6">
			<div className="flex flex-col sm:flex-row sm:items-center justify-between mb-2 gap-2 space-y-4 sm:space-y-0">
				<h3 className="text-lg font-semibold text-[#16013e] flex items-center">
					{icon && <span className="mr-2">{icon}</span>}
					{title}
				</h3>
				<div className="flex sm:items-center justify-between gap-4">
					{reportScoreData && currentScore !== undefined && (
						<ReportScore
							data={reportScoreData}
							width={80}
							height={40}
							color="#00a9af"
							showScore={showReportScore}
							lineColor={lineColor}
							currentScore={currentScore}
							isAdminView={isAdminView}
						/>
					)}
					{isAdminView && reportScoreDetails && reportScoreDetails.length >= 2 && (
						<Button
							variant="outline"
							size="sm"
							onClick={() => setShowProgressionChart(!showProgressionChart)}
							className="flex items-center gap-2 font-medium w-40"
						>
							<ChartBarIcon className="h-4 w-4" />
							<span>{showProgressionChart ? "Hide" : "Show"} Chart</span>
							{showProgressionChart ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
						</Button>
					)}
				</div>
			</div>
			<div className="border-t border-gray-200 my-2"></div>
			<div className="mt-3">
				{isAdminView && showDataTable && showProgressionChart && renderProgressionChart()}
				{children}
			</div>
			{showCommentButton && (
				<div className="flex justify-between items-center mt-8">
					<div className="flex items-center">
						<button
							onClick={() => setIsCommentDialogOpen(true)}
							className={`flex items-center ${
								isCommented ? "text-[#00a9af]" : "text-gray-400"
							} hover:text-[#00a9af] transition-colors duration-200`}
							title={isCommented ? "Comment added" : "Add Comment"}
						>
							{isCommented ? (
								<>
									<span className="text-sm mr-1">Comment added</span>
									<CheckCircleIcon className="h-5 w-5" />
								</>
							) : (
								<>
									<span className="text-sm mr-1">Comment</span>
									<PlusCircleIcon className="h-5 w-5" />
								</>
							)}
						</button>
					</div>
				</div>
			)}

			<Dialog open={isCommentDialogOpen} onOpenChange={setIsCommentDialogOpen}>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>Add a comment or clarification to this summary</DialogTitle>
					</DialogHeader>
					{canComment && (
						<Textarea
							value={comment}
							disabled={!canComment}
							onChange={(e) => setComment(e.target.value)}
							placeholder="Type your comment here..."
							className="min-h-[100px]"
						/>
					)}
					{!canComment && <p className="border p-2">{comment}</p>}

					<DialogFooter>
						{canComment && (
							<>
								<Button variant="outline" onClick={() => setIsCommentDialogOpen(false)}>
									Cancel
								</Button>
								<Button onClick={handleAddComment}>Save</Button>
							</>
						)}
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default ReportCard;
