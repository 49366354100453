import { useState, useEffect, useCallback, useRef } from "react";
import { useAppContext } from "../contexts/AppContext";

interface QueueUpdatePayload {
	jobState: "none" | "active" | "waiting" | "prioritized" | "completed";
	callState: "none" | "started" | "pending" | "ended" | "canceled";
	timeLeft?: number;
	sessionId?: string;
}

interface JoinQueueResponse {
	jobState: QueueUpdatePayload["jobState"];
	callState: QueueUpdatePayload["callState"];
}

interface QueueEventPayload {
	userId: string;
	sessionId: string;
	vapiCallId?: string;
}

interface QueueSystemProps {
	userId: string;
	onReadyToStart?: () => void;
	onCancelPendingCall?: () => void;
}

interface QueueState {
	inQueue: boolean;
	jobState: QueueUpdatePayload["jobState"];
	callState: QueueUpdatePayload["callState"];
	timeLeft: number;
	isLoading: boolean;
	scheduleDate: Date | null;
}

export const useQueueSystem = ({ userId, onReadyToStart, onCancelPendingCall }: QueueSystemProps) => {
	const { status, on, off, emit } = useAppContext();
	const sessionIdRef = useRef<string | null>(null);
	const hasToWaitRef = useRef<boolean>(false);
	const stateRef = useRef<QueueState>({
		inQueue: false,
		jobState: "none",
		callState: "none",
		timeLeft: -1,
		isLoading: false,
		scheduleDate: null
	});

	const [, setUpdateTrigger] = useState({});

	const updateState = useCallback((updater: (prev: QueueState) => QueueState) => {
		stateRef.current = updater(stateRef.current);
		setUpdateTrigger({});
	}, []);

	useEffect(() => {
		if (status !== "connected") return;

		const inQueue = sessionStorage.getItem("inQueue") === "true";
		updateState((prev) => ({ ...prev, inQueue }));
	}, [status, emit, userId, updateState]);

	useEffect(() => {
		const queueUpdateHandler = (data: QueueUpdatePayload) => {
			if (sessionIdRef.current !== data.sessionId) return;

			if (stateRef.current.jobState === "prioritized" && data.jobState === "active" && hasToWaitRef.current && onReadyToStart) {
				onReadyToStart();
			}
			if (stateRef.current.callState === "pending" && data.callState === "canceled" && onCancelPendingCall) {
				onCancelPendingCall();
			}
			updateState((prev) => ({
				...prev,
				jobState: data.jobState,
				callState: data.callState,
				timeLeft: data.timeLeft || -1,
				inQueue: data.jobState === "active" || data.jobState === "waiting" || data.jobState === "prioritized"
			}));

			sessionStorage.setItem("inQueue", String(stateRef.current.inQueue));
		};

		on("queueUpdate", queueUpdateHandler);
		return () => off("queueUpdate", queueUpdateHandler);
	}, [on, off, onReadyToStart, updateState]);

	const joinCallQueue = useCallback(
		async (sessionId: string) => {
			if (userId === "") {
				throw new Error("User ID is required");
			}
			sessionIdRef.current = sessionId;
			updateState((prev) => ({ ...prev, isLoading: true, sessionId }));

			const response = await emit<JoinQueueResponse>("joinCallQueue", {
				userId,
				sessionId
			} as QueueEventPayload);

			updateState((prev) => ({
				...prev,
				jobState: response.jobState,
				callState: response.callState,
				inQueue: true,
				isLoading: false
			}));

			hasToWaitRef.current = response.jobState === "waiting" || response.jobState === "prioritized";
			sessionStorage.setItem("inQueue", "true");
			return { hasToWait: hasToWaitRef.current };
		},
		[userId, emit, updateState]
	);

	const leaveCallQueue = useCallback(
		async (sessionId: string) => {
			if (userId === "") throw new Error("User ID is required");

			await emit("leaveCallQueue", { userId, sessionId });

			updateState((prev) => ({ ...prev, inQueue: false }));
			sessionStorage.setItem("inQueue", "false");
		},
		[userId, emit, updateState]
	);

	const startCall = useCallback(
		async (sessionId: string, vapiCallId: string) => {
			if (userId === "") throw new Error("User ID is required");

			updateState((prev) => ({ ...prev, isLoading: true }));

			await emit("startCall", {
				userId,
				sessionId,
				vapiCallId
			} as QueueEventPayload);

			updateState((prev) => ({ ...prev, isLoading: false }));
		},
		[userId, emit, updateState]
	);

	const getQueueUpdate = useCallback(
		async (sessionId: string) => {
			if (sessionId === "") {
				sessionStorage.setItem("inQueue", "false");
				updateState((prev) => ({ ...prev, inQueue: false }));
			}
			const data = await emit("getQueueUpdate", { userId, sessionId });

			if (!data.hasJob) {
				updateState((prev) => ({ ...prev, inQueue: false, sessionId }));
				sessionStorage.setItem("inQueue", "false");
			}
			return data;
		},
		[userId, emit, updateState]
	);

	return {
		...stateRef.current,
		joinCallQueue,
		leaveCallQueue,
		startCall,
		getQueueUpdate
	};
};
