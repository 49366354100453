import { Type, Expose, Transform } from "class-transformer";
import { ViewColumn } from "./typeorm-mocks";
import { IsUUID, IsString, IsDateString, IsNumber, IsArray, IsBoolean } from "class-validator";
import { dateTransformer } from "./dateTransformer";
import { numberTransformer } from "./numberTransformer";
import { ExperienceType } from "../schemas/ExperienceType";

export class AdminAssignmentView {
	@Expose()
	@ViewColumn()
	@IsUUID()
	assignmentId!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	assignmentTitle!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	assignmentDescription!: string;

	@Expose()
	@ViewColumn()
	ownerId!: string;

	@ViewColumn()
	@Type(() => Date)
	@Transform(({ value }) => value.map((date: string) => dateTransformer(date)))
	scheduledDates!: Date[];

	@ViewColumn()
	scheduledIds!: string[];

	@ViewColumn()
	scheduledDescriptions!: string[];

	@ViewColumn()
	@IsUUID()
	courseId!: string;

	@ViewColumn()
	@IsString()
	courseName!: string;

	@ViewColumn()
	@IsUUID()
	clientId!: string;

	@ViewColumn()
	@IsString()
	clientName!: string;

	@ViewColumn()
	@Type(() => Date)
	@Transform(({ value }) => dateTransformer(value))
	startDate!: Date;

	@ViewColumn()
	@Type(() => Date)
	@Transform(({ value }) => dateTransformer(value))
	@IsDateString()
	endDate!: Date;

	@ViewColumn()
	@IsNumber()
	@Transform(({ value }) => numberTransformer(value))
	enrolledUserCount!: number;

	@ViewColumn()
	@IsNumber()
	@Transform(({ value }) => numberTransformer(value))
	sessionsAssigned!: number;

	@ViewColumn()
	@IsNumber()
	@Transform(({ value }) => numberTransformer(value))
	totalExpectedSessions!: number;

	@ViewColumn()
	@IsNumber()
	@Transform(({ value }) => numberTransformer(value))
	submissionCount!: number;

	@ViewColumn()
	@IsNumber()
	@Transform(({ value }) => numberTransformer(value))
	participatedUserCount!: number;

	@ViewColumn()
	@IsNumber()
	@Transform(({ value }) => numberTransformer(value))
	participationRate!: number;

	@ViewColumn()
	@IsNumber()
	@Transform(({ value }) => numberTransformer(value))
	totalSessionsToGo!: number;

	@ViewColumn()
	@IsNumber()
	@Transform(({ value }) => numberTransformer(value))
	projectedHours!: number;

	@ViewColumn()
	@IsNumber()
	@Transform(({ value }) => numberTransformer(value))
	serviceHoursUsed!: number;

	@ViewColumn()
	@IsString()
	satisfaction!: "Very Low" | "Low" | "Medium" | "High" | "Very High";

	@ViewColumn()
	@IsString()
	status!: "Drafts" | "Ongoing" | "Complete";

	@ViewColumn()
	@IsNumber()
	@Transform(({ value }) => numberTransformer(value))
	flags!: number;

	@ViewColumn()
	@IsString()
	assignmentCode!: string;

	@Expose()
	@ViewColumn()
	@IsArray()
	@Transform(({ value }) => (value ? value.filter(Boolean) : []))
	competencies!: Array<{ id: string; name: string; description: string }>;

	@ViewColumn()
	@IsString()
	experienceType!: ExperienceType;

	@ViewColumn()
	@IsString()
	lastEditedBy!: string;

	@ViewColumn()
	@IsNumber()
	plGroupId!: number | null;

	@IsBoolean()
	@ViewColumn()
	includeTranscript!: boolean;

	@IsBoolean()
	@ViewColumn()
	includeAudio!: boolean;

	@IsBoolean()
	@ViewColumn()
	includeUserIdentification!: boolean;

	@IsBoolean()
	@ViewColumn()
	soloMode!: boolean;

	getPlGroupIdUrl() {
		return `https://dashboard.promptlayer.com/workspace/8303/group/${this.plGroupId}`;
	}

	getSharedLink(): string {
		return `${window.location.origin}/assignment/${this.assignmentCode}`;
	}
}
