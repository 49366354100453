import React, { useState, useEffect, useCallback } from "react";
import { useAppContext } from "../../contexts/AppContext";
import { Input } from "../shadcn-ui/Input";
import { Label } from "../shadcn-ui/Label";
import { Button } from "../shadcn-ui/Button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "../shadcn-ui/Dialog";
import Spinner from "../Utility/Spinner";
import { useParams, useNavigate } from "react-router-dom";
import { useQueueSystem } from "../../hooks/useQueueSystem";

const QueueTestPage: React.FC = () => {
	const [testName, setTestName] = useState("");
	const { status } = useAppContext();
	const { sessionId } = useParams();
	const navigate = useNavigate();

	const { inQueue, getQueueUpdate, jobState, callState, timeLeft, isLoading, joinCallQueue, leaveCallQueue, startCall } = useQueueSystem({
		userId: testName
	});

	const [showScheduleDialog, setShowScheduleDialog] = useState(false);

	useEffect(() => {
		if (testName !== "") {
			sessionStorage.setItem("testName", testName);
		} else {
			const testName = sessionStorage.getItem("testName");
			if (testName !== null && testName !== "") {
				setTestName(testName);
			}
		}

		return () => {};
	}, [testName, inQueue]);

	useEffect(() => {
		if (status === "connected") {
			getQueueUpdate(sessionId || "");
		}

		return () => {};
	}, [getQueueUpdate, inQueue, sessionId, status]);

	useEffect(() => {
		const originalTitle = document.title;
		document.title =
			"Queue Test - " +
			testName +
			(inQueue ? " (In Queue) " : " ") +
			(jobState !== "none" ? jobState + " - " : "") +
			(callState !== "none" ? callState : "");

		return () => {
			document.title = originalTitle;
		};
	}, [testName, inQueue, jobState, callState]);

	const handleJoinQueue = async () => {
		if (!sessionId) {
			const newSessionId = crypto.randomUUID();
			await joinCallQueue(newSessionId);
			navigate(`/queue-test/${newSessionId}`);
		} else {
			await joinCallQueue(sessionId);
			navigate(`/queue-test/${sessionId}`);
		}
	};

	const handleLeaveQueue = async () => {
		if (!sessionId) throw new Error("Session ID is required");
		await leaveCallQueue(sessionId);

		navigate("/queue-test");
	};

	useEffect(() => {
		if (callState === "canceled" || jobState === "completed") {
			navigate("/queue-test");
		}
	}, [callState, jobState, navigate]);

	// const handleScheduleDialogClose = () => {
	// 	setShowScheduleDialog(false);
	// };

	// const handleScheduleDialogSubmit = (minutes: number) => {
	// 	setShowScheduleDialog(false);

	// 	const targetDate = new Date(Date.now() + minutes * 60000);
	// 	emit("scheduleCall", { userId: testName, targetDate: targetDate.toISOString(), sessionId });
	// };

	// const handleScheduleDialogOpen = () => {
	// 	setShowScheduleDialog(true);
	// };

	return (
		<div className="flex flex-col items-center justify-center w-full max-w-8xl mx-auto">
			<div className="w-full rounded-lg border bg-card text-card-foreground p-4">
				<h1>Queue Test Page</h1>
				{sessionId && <p>Session ID: {sessionId}</p>}
				{/* {!sessionId && <Button onClick={generateSessionId}>Generate Session ID</Button>} */}
				{/* <Dialog open={showScheduleDialog} onOpenChange={handleScheduleDialogClose}>
					<DialogContent>
						<DialogHeader>
							<DialogTitle>Schedule a call</DialogTitle>
							<DialogDescription>Select when you would like to schedule your call</DialogDescription>
						</DialogHeader>
						<div className="flex flex-col gap-4">
							<Button onClick={() => handleScheduleDialogSubmit(1)}>1 minute from now</Button>
							<Button onClick={() => handleScheduleDialogSubmit(2)}>2 minute from now</Button>
							<Button onClick={() => handleScheduleDialogSubmit(5)}>5 minutes from now</Button>
							<Button onClick={() => handleScheduleDialogSubmit(10)}>10 minutes from now</Button>
						</div>
					</DialogContent>
				</Dialog> */}

				{status === "connected" && (
					<>
						<div className="mb-4"></div>
						<div className="flex items-center gap-4 max-w-xl mb-4">
							<Label className="min-w-[80px]">Test Name</Label>
							<Input value={testName} onChange={(e) => setTestName(e.target.value)} />
						</div>
						{isLoading ? (
							<Spinner />
						) : inQueue ? (
							<div className="flex items-center gap-4 max-w-xl mb-4">
								{jobState && (
									<>
										{(jobState === "waiting" || jobState === "prioritized") && (
											<>
												<p>You are in the queue. Waiting for call...</p>
												<Spinner />
												<Button variant="destructive" onClick={handleLeaveQueue}>
													Leave Call Queue
												</Button>
											</>
										)}

										{jobState === "active" && (
											<>
												{callState === "started" && (
													<>
														<p>You are in a call. </p>
														<Button variant="destructive" onClick={handleLeaveQueue}>
															End Call
														</Button>
													</>
												)}
												{(callState === "pending" || callState === "ended" || callState === "canceled") && (
													<>
														<p> your session is ready</p>
														{timeLeft !== -1 && <p>Time left before auto-cancel: {timeLeft} seconds</p>}

														{sessionId && (
															<Button onClick={() => startCall(sessionId, crypto.randomUUID())}>Start call</Button>
														)}
														<Button variant="destructive" onClick={handleLeaveQueue}>
															Cancel
														</Button>
													</>
												)}
											</>
										)}
									</>
								)}
							</div>
						) : (
							<div className="flex flex-col gap-4 max-w-xl">
								<Button onClick={handleJoinQueue}>Start web call now</Button>
								{/* {scheduleDate === null && <Button onClick={handleScheduleDialogOpen}>Schedule a call</Button>}
								{scheduleDate !== null && <p>Call scheduled for {scheduleDate.toLocaleString()}</p>} */}
							</div>
						)}
						<div className="mb-14"></div>
						<div className="flex items-center gap-4 max-w-xl mb-4">
							<p>jobState:{jobState}</p>
							<p>callState:{callState}</p>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default QueueTestPage;
