import React, { useState, useEffect } from "react";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../shadcn-ui/Tabs";
import { CostService } from "../../services/CostService";
import { ClientCostSummaryView } from "../../types/ClientCostSummaryView";
import { SessionCostSummaryView } from "../../types/SessionCostSummaryView";
import { UserCostSummaryView } from "../../types/UserCostSummaryView";
import { DataTable, ExtendedColumnDef, Filter } from "../shadcn-ui/DataTable";

const AdminCostPage: React.FC = () => {
	const [clientCosts, setClientCosts] = useState<ClientCostSummaryView[]>([]);
	const [sessionCosts, setSessionCosts] = useState<SessionCostSummaryView[]>([]);
	const [userCosts, setUserCosts] = useState<UserCostSummaryView[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const clients = await CostService.getClientsCost();
				setClientCosts(clients);

				const sessions = await CostService.getSessionsCosts();

				setSessionCosts(sessions);

				const users = await CostService.getUserCosts();
				setUserCosts(users);

				console.log(clients);
				console.log(sessions);
				console.log(users);
			} catch (error) {
				console.error("Error fetching cost data:", error);
			}
		};

		fetchData();
	}, []);

	const clientColumns: ExtendedColumnDef<ClientCostSummaryView>[] = [
		{
			accessorKey: "clientName",
			header: "Client Name"
		},
		{
			accessorKey: "clientId",
			header: "Client ID"
		},
		{
			accessorKey: "modelCost",
			header: "LLM Cost",
			cell: ({ row }) => {
				const model = "$" + row.original.modelCost.toFixed(2) || "";
				return <div>{model}</div>;
			}
		},
		{
			accessorKey: "transcriberCost",
			header: "Deepgram Cost",
			cell: ({ row }) => {
				const model = "$" + row.original.transcriberCost.toFixed(2) || "";
				return <div>{model}</div>;
			}
		},
		{
			accessorKey: "voiceCost",
			header: "Voice Cost",
			cell: ({ row }) => {
				const model = "$" + row.original.voiceCost.toFixed(2) || "";
				return <div>{model}</div>;
			}
		},

		{
			accessorKey: "vapiCost",
			header: "Vapi Cost",
			cell: ({ row }) => {
				const model = "$" + row.original.vapiCost.toFixed(2) || "";
				return <div>{model}</div>;
			}
		},
		{
			accessorKey: "totalCost",
			header: "Cost",
			cell: ({ row }) => {
				const cost = row.original.totalCost || 0;
				return <div>{"$" + cost.toFixed(2)}</div>;
			}
		},
		{
			accessorKey: "totalMinutes",
			header: "Usage",
			cell: ({ row }) => {
				const totalMinutes = row.original.totalMinutes || 0;
				const hours = Math.floor(totalMinutes / 60);
				const minutes = Math.floor(totalMinutes % 60);
				const seconds = (totalMinutes % 1) * 60;

				return (
					<div>
						{hours > 0 ? `${hours}h ` : ""}
						{minutes > 0 ? `${minutes}m ` : ""}
						{seconds > 0 ? `${seconds.toFixed(0)}s` : ""}
					</div>
				);
			}
		}
	];

	const sessionColumns: ExtendedColumnDef<SessionCostSummaryView>[] = [
		{
			accessorKey: "clientName",
			header: "Client Name"
		},
		{
			accessorKey: "sessionId",
			header: "Session ID",
			initiallyHidden: true
		},
		{
			accessorKey: "userEmail",
			header: "User Email"
		},
		{
			accessorKey: "userId",
			header: "User ID",
			initiallyHidden: true
		},
		{
			accessorKey: "sessionDate",
			header: "Session Date",
			sortingFn: "datetime",
			cell: ({ row }) => {
				const date = new Date(row.original.sessionDate);
				return <div>{date.toLocaleDateString()}</div>;
			}
		},
		{
			accessorKey: "assignmentId",
			header: "Assignment ID",
			initiallyHidden: true
		},
		{
			accessorKey: "assignmentName",
			header: "Assignment Name"
		},
		{
			accessorKey: "modelCost",
			header: "LLM Cost",
			cell: ({ row }) => {
				const model = "$" + row.original.modelCost.toFixed(2) || "";
				return <div>{model}</div>;
			}
		},
		{
			accessorKey: "transcriberCost",
			header: "Deepgram Cost",
			cell: ({ row }) => {
				const model = "$" + row.original.transcriberCost.toFixed(2) || "";
				return <div>{model}</div>;
			}
		},
		{
			accessorKey: "voiceCost",
			header: "Voice Cost",
			cell: ({ row }) => {
				const model = "$" + row.original.voiceCost.toFixed(2) || "";
				return <div>{model}</div>;
			}
		},
		{
			accessorKey: "vapiCost",
			header: "Vapi Cost",
			cell: ({ row }) => {
				const model = "$" + row.original.vapiCost.toFixed(2) || "";
				return <div>{model}</div>;
			}
		},
		{
			accessorKey: "totalCost",
			header: "Cost",
			cell: ({ row }) => {
				const cost = row.original.totalCost || 0;
				return <div>{"$" + cost.toFixed(2)}</div>;
			}
		},
		{
			accessorKey: "totalMinutes",
			header: "Usage",
			cell: ({ row }) => {
				const totalMinutes = row.original.totalMinutes || 0;
				const hours = Math.floor(totalMinutes / 60);
				const minutes = Math.floor(totalMinutes % 60);
				const seconds = (totalMinutes % 1) * 60;

				return (
					<div>
						{hours > 0 ? `${hours}h ` : ""}
						{minutes > 0 ? `${minutes}m ` : ""}
						{seconds > 0 ? `${seconds.toFixed(0)}s` : ""}
					</div>
				);
			}
		}
	];

	const userColumns: ExtendedColumnDef<UserCostSummaryView>[] = [
		{
			accessorKey: "userEmail",
			header: "User Email"
		},
		{
			accessorKey: "userId",
			header: "User ID",
			initiallyHidden: true
		},
		{
			accessorKey: "clientName",
			header: "Client Name"
		},
		{
			accessorKey: "clientId",
			header: "Client ID",
			initiallyHidden: true
		},

		{
			accessorKey: "modelCost",
			header: "LLM Cost",
			cell: ({ row }) => {
				const model = "$" + row.original.modelCost.toFixed(2) || "";
				return <div>{model}</div>;
			}
		},
		{
			accessorKey: "transcriberCost",
			header: "Deepgram Cost",
			cell: ({ row }) => {
				const model = "$" + row.original.transcriberCost.toFixed(2) || "";
				return <div>{model}</div>;
			}
		},
		{
			accessorKey: "voiceCost",
			header: "Voice Cost",
			cell: ({ row }) => {
				const model = "$" + row.original.voiceCost.toFixed(2) || "";
				return <div>{model}</div>;
			}
		},
		{
			accessorKey: "vapiCost",
			header: "Vapi Cost",
			cell: ({ row }) => {
				const model = "$" + row.original.vapiCost.toFixed(2) || "";
				return <div>{model}</div>;
			}
		},
		{
			accessorKey: "totalCost",
			header: "Cost",
			cell: ({ row }) => {
				const cost = row.original.totalCost || 0;
				return <div>{"$" + cost.toFixed(2)}</div>;
			}
		},
		{
			accessorKey: "totalMinutes",
			header: "Usage",
			cell: ({ row }) => {
				const totalMinutes = row.original.totalMinutes || 0;
				const hours = Math.floor(totalMinutes / 60);
				const minutes = Math.floor(totalMinutes % 60);
				const seconds = (totalMinutes % 1) * 60;

				return (
					<div>
						{hours > 0 ? `${hours}h ` : ""}
						{minutes > 0 ? `${minutes}m ` : ""}
						{seconds > 0 ? `${seconds.toFixed(0)}s` : ""}
					</div>
				);
			}
		}
	];

	const clientFilters: Filter[] = [
		{
			columnId: "clientName",
			type: "select",
			options: clientCosts.map((client) => ({ label: client.clientName, value: client.clientName }))
		}
	];

	const sessionFilters: Filter[] = [
		{
			columnId: "clientName",
			type: "select",
			options: Array.from(new Set(sessionCosts.map((session) => session.clientName))).map((clientName) => ({
				label: clientName,
				value: clientName
			}))
		},
		{
			columnId: "assignmentName",
			type: "select",
			options: Array.from(new Set(sessionCosts.map((session) => session.assignmentName))).map((assignmentName) => ({
				label: assignmentName,
				value: assignmentName
			}))
		},

		{
			columnId: "userEmail",
			type: "input",
			placeholder: "Filter by User Email"
		}
	];

	const userFilters: Filter[] = [
		{
			columnId: "clientName",
			type: "select",
			options: Array.from(new Set(userCosts.map((user) => user.clientName))).map((clientName) => ({
				label: clientName,
				value: clientName
			}))
		}
	];

	return (
		<div>
			<h1>Admin Cost Page</h1>
			<Tabs defaultValue="clients">
				<TabsList>
					<TabsTrigger value="clients">Clients</TabsTrigger>
					<TabsTrigger value="sessions">Sessions</TabsTrigger>
					<TabsTrigger value="users">Users</TabsTrigger>
				</TabsList>

				<TabsContent value="clients">
					<h2>Client Costs</h2>
					<DataTable enableSorting={true} columns={clientColumns} data={clientCosts} filters={clientFilters} />
				</TabsContent>

				<TabsContent value="sessions">
					<h2>Session Costs</h2>
					<DataTable enableSorting={true} columns={sessionColumns} data={sessionCosts} filters={sessionFilters} />
				</TabsContent>

				<TabsContent value="users">
					<h2>User Costs</h2>
					<DataTable enableSorting={true} columns={userColumns} data={userCosts} filters={userFilters} />
				</TabsContent>
			</Tabs>
		</div>
	);
};

export default AdminCostPage;
