import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { ClientService } from "../services/ClientService";

interface ClientSettingsContextType {
	clientSettings: any | null;
	isLoading: boolean;
	error: Error | null;
}

const ClientSettingsContext = createContext<ClientSettingsContextType | undefined>(undefined);

export const ClientSettingsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [clientSettings, setClientSettings] = useState<any | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<Error | null>(null);

	useEffect(() => {
		const fetchClientSettings = async () => {
			try {
				const settings = await ClientService.getClient();
				setClientSettings(settings);
			} catch (error) {
				setError(error as Error);
				console.error("Failed to fetch client settings:", error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchClientSettings();
	}, []);

	return <ClientSettingsContext.Provider value={{ clientSettings, isLoading, error }}>{children}</ClientSettingsContext.Provider>;
};

export const useClientSettings = () => {
	const context = useContext(ClientSettingsContext);
	if (context === undefined) {
		throw new Error("useClientSettings must be used within a ClientSettingsProvider");
	}
	return context;
};
