import React from "react";
import { Card, CardContent } from "../../../shadcn-ui/Card";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "../../../shadcn-ui/Carousel";
import { JobPosting } from "../../../../services/AssignmentService";
import monsterLogo from "../../../../assets/images/monsterLogo.png";

interface JobPostingsProps {
	jobTitle: string;
	options: JobPosting[];
	onSelect: (option: JobPosting) => void;
}

const JobPostings: React.FC<JobPostingsProps> = ({ jobTitle, options, onSelect }) => {
	if (!jobTitle || options.length === 0) return null;

	return (
		<div className="mt-4">
			{/* Section Headers */}
			<div className="flex mb-2">
				<h3 className="text-sm font-medium text-gray-700 w-1/3">Custom Job Title</h3>
				<div className="w-2/3 flex justify-between">
					<h3 className="text-sm font-medium text-gray-700">Posted Jobs</h3>
					<span className="text-xs text-gray-500">{options.length} positions found</span>
				</div>
			</div>

			<div className="flex gap-8">
				{/* Custom Job Title Card */}
				<div className="w-1/3">
					<Card
						className={`cursor-pointer hover:border-[#00a9af] transition-colors h-[120px]`}
						onClick={() =>
							onSelect({
								title: jobTitle,
								companyName: "Company - TBD",
								location: "Location - TBD",
								postingId: "custom",
								summary: "Please enter a job description for this custom position."
							})
						}
					>
						<CardContent className="p-4">
							<h4 className="text-sm font-medium text-[#00a9af] truncate">{jobTitle}</h4>
							<p className="text-sm text-gray-500 mt-2">Company - TBD</p>
							<p className="text-sm text-gray-500">Location - TBD</p>
						</CardContent>
					</Card>
				</div>

				{/* Carousel Section */}
				<div className="relative w-2/3">
					<Carousel
						opts={{
							align: "start",
							loop: true
						}}
						className="w-full"
					>
						<CarouselContent className="-ml-2 md:-ml-3">
							{options.map((option, index) => (
								<CarouselItem key={option.postingId || index} className="pl-2 md:pl-3 basis-1/2 md:basis-1/2">
									<Card
										className={`cursor-pointer hover:border-[#00a9af] transition-colors h-[120px] relative`}
										onClick={() => onSelect(option)}
									>
										<CardContent className="p-4">
											<h4 className="text-sm font-medium text-[#00a9af] truncate">{option.title}</h4>
											<p className="text-sm text-gray-500 mt-2">{option.companyName}</p>
											<p className="text-sm text-gray-500">{option.location}</p>
											<img src={monsterLogo} alt="Monster.com" className="absolute bottom-3 right-3 w-10" />
										</CardContent>
									</Card>
								</CarouselItem>
							))}
						</CarouselContent>
						<CarouselPrevious className="-left-4" />
						<CarouselNext className="-right-4" />
					</Carousel>
				</div>
			</div>
		</div>
	);
};

export default JobPostings;
