import React, { useState, useEffect } from "react";
import { Command, CommandGroup, CommandInput, CommandItem } from "../../../shadcn-ui/Command";
import { Popover, PopoverContent, PopoverTrigger } from "../../../shadcn-ui/Popover";
import { Button } from "../../../shadcn-ui/Button";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "../../../../lib/utils";
import jobTitlesData from "../../../../constants/JobTitles.json";

interface JobTitleSelectProps {
	value: string;
	onChange: (value: string) => void;
	onJobSearch?: (title: string) => void;
	disabled?: boolean;
}

const JobTitleSelect: React.FC<JobTitleSelectProps> = ({ value, onChange, onJobSearch, disabled }) => {
	const [open, setOpen] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [filteredTitles, setFilteredTitles] = useState(jobTitlesData);

	// Check if current value is a custom title
	const isCustomTitle = !jobTitlesData.some((job) => job.name === value) && value !== "";

	useEffect(() => {
		const searchLower = searchValue.toLowerCase();
		const filtered =
			searchValue === ""
				? jobTitlesData
				: jobTitlesData.filter((job) => job.name.toLowerCase().includes(searchLower) || job.title.toLowerCase().includes(searchLower));
		setFilteredTitles(filtered);
	}, [searchValue]);

	const handleSelect = (currentValue: string) => {
		onChange(currentValue);
		setOpen(false);
		if (onJobSearch) {
			onJobSearch(currentValue);
		}
	};

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					role="combobox"
					aria-expanded={open}
					className={cn("w-full justify-between h-11 px-3 py-2 text-sm bg-white", disabled && "opacity-50 cursor-not-allowed")}
					disabled={disabled}
				>
					{value ? `${value}${isCustomTitle ? " (custom)" : ""}` : "Select job title..."}
					<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-[--radix-popover-trigger-width] p-0">
				<Command>
					<CommandInput placeholder="Search job titles or create your own" value={searchValue} onValueChange={setSearchValue} />
					<CommandGroup className="max-h-[300px] overflow-y-auto">
						{filteredTitles.length > 0 ? (
							filteredTitles.map((job) => (
								<CommandItem key={job.key} value={job.name} onSelect={handleSelect}>
									<Check className={cn("mr-2 h-4 w-4", value === job.name ? "opacity-100" : "opacity-0")} />
									{job.name}
								</CommandItem>
							))
						) : (
							<CommandItem value={searchValue} onSelect={handleSelect}>
								<Check className={cn("mr-2 h-4 w-4", value === searchValue ? "opacity-100" : "opacity-0")} />
								{searchValue}
								<span className="ml-2 text-muted-foreground">(custom)</span>
							</CommandItem>
						)}
					</CommandGroup>
				</Command>
			</PopoverContent>
		</Popover>
	);
};

export default JobTitleSelect;
