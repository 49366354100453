import axios from "axios";
import { jwtDecode } from "jwt-decode";

// Add an axios interceptor to include the token in the headers
axios.interceptors.request.use(
	(config) => {
		const token = TokenManager.getToken();
		if (token) {
			if (!config.headers["skipAuthRefresh"]) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export class TokenPayload {
	sub!: string; // frontegg user id

	name!: string;

	email!: string;

	email_verified!: boolean;

	metadata!: {
		role: { name: string };
		type: { name: string };
		group: Array<unknown>;
		jobTitle: string;
	};

	roles!: ("Faculty" | "Owner" | "InstageAdmin" | "Admin" | "ReadOnly")[];

	permissions!: string[];

	tenantId!: string;

	tenantIds!: string[];

	profilePictureUrl!: string;

	sid!: string;

	type!: string;

	applicationId!: string;

	aud!: string;

	iss!: string;

	iat!: number;

	exp!: number;
}

class TokenManager {
	private static roles: ("Faculty" | "Owner" | "InstageAdmin" | "Admin" | "ReadOnly")[] = [];

	static isInstageAdmin(): boolean {
		return this.roles.includes("InstageAdmin");
	}

	static isOwner(): boolean {
		return this.roles.includes("Owner");
	}

	static isAdmin(): boolean {
		return this.roles.includes("Admin");
	}

	static isFaculty(): boolean {
		return this.roles.includes("Faculty");
	}

	static isMember(): boolean {
		return this.roles.includes("ReadOnly");
	}

	static hasMemberClearance(): boolean {
		return this.getClearanceLevel() >= 1;
	}

	static hasFacultyClearance(): boolean {
		return this.getClearanceLevel() >= 2;
	}

	static hasAdminClearance(): boolean {
		return this.getClearanceLevel() >= 3;
	}

	static hasOwnerClearance(): boolean {
		return this.getClearanceLevel() >= 4;
	}

	static hasInstageAdminClearance(): boolean {
		return this.getClearanceLevel() >= 5;
	}

	static InstageAdminClearanceLevel = 5;

	static OwnerClearanceLevel = 4;

	static AdminClearanceLevel = 3;

	static FacultyClearanceLevel = 2;

	static MemberClearanceLevel = 1;

	private static getClearanceLevel(): number {
		if (sessionStorage.getItem("isStudentMode") === "true" && this.isFaculty()) {
			return this.MemberClearanceLevel;
		}

		if (this.isInstageAdmin()) return this.InstageAdminClearanceLevel;
		if (this.isOwner()) return this.OwnerClearanceLevel;
		if (this.isAdmin()) return this.AdminClearanceLevel;
		if (this.isFaculty()) return this.FacultyClearanceLevel;
		return this.MemberClearanceLevel;
	}

	private static token: string | null = null;

	static setToken(newToken: string): void {
		this.token = newToken;

		const tokenPayload = jwtDecode(newToken) as TokenPayload;
		this.roles = tokenPayload.roles;

		// console.log(this.getClearanceLevel());
	}

	static getToken(): string | null {
		return this.token;
	}

	static clearToken(): void {
		this.token = null;
	}
}

export default TokenManager;
