import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { PencilIcon, ClipboardIcon } from "@heroicons/react/20/solid";
import { AssignmentPayload } from "../../../../types/AssignmentPayload";
import { AssignmentService } from "../../../../services/AssignmentService";
import { Button } from "../../../shadcn-ui/Button";
import { AdminAssignmentView } from "@/src/types/AdminAssignmentView";
import { StudentNotications } from "../../../../types/AssignmentPayload";
import { Loader2 } from "lucide-react";

import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle
} from "../../../shadcn-ui/AlertDialog";
import { useAppContext } from "../../../../contexts/AppContext";
import { SchedulePayload } from "../../../../types/SchedulePayload";
import { InterviewStageType, InterviewStage } from "../../../../types/InterviewPayload";
// Define props for the ReviewAssignment component
export interface ReviewAssignmentProps {
	values: any;
	onNext: () => void;
	onPrevious: () => void;
	onEdit: (step: number) => void;
	isEditMode: boolean;
	onSubmit: (assignmentPayload: AdminAssignmentView) => void;
	assignmentType?: "reflection" | "interview";
	clientSettings: any;
}

interface SortedSession extends InterviewStage {
	date: Date | null;
	startDate: Date | null;
}

// Add this helper function for interview sessions
const getSortedInterviewSessions = (stages: InterviewStage[], sessions: any[]): SortedSession[] => {
	if (!stages || !sessions) return [];

	// Create a new array with stages and their corresponding sessions
	return stages
		.filter((stage) => stage.enabled)
		.map((stage, index) => {
			// Find the session that corresponds to this stage's position in the stages array
			const stageIndex = stages.findIndex((s) => s.interviewStageOrderNumber === stage.interviewStageOrderNumber);
			const session = sessions[stageIndex];

			return {
				...session,
				...stage, // Include all Stage properties
				title: stage.title,
				interviewStageType: stage.interviewStageType as InterviewStageType,
				date: session?.date || null,
				startDate: session?.startDate || null
			};
		});
};

// ReviewAssignment component for reviewing and submitting assignment details
const ReviewAssignment: React.FC<ReviewAssignmentProps> = ({
	values,
	onSubmit,
	onNext,
	onPrevious,
	onEdit,
	isEditMode,
	assignmentType = "reflection",
	clientSettings
}) => {
	const { assignmentId } = useParams<{ step?: string; assignmentId?: string }>();
	const [isDeployDialogOpen, setIsDeployDialogOpen] = useState(false);
	const [isDeploying, setIsDeploying] = useState(false);
	const { instageUser } = useAppContext();

	const location = useLocation();
	const isAdminPath = location.pathname.startsWith("/admin");

	// Format date to a readable string
	const formatDate = (date: Date | null | undefined) => {
		return date ? format(date, "MMMM d, yyyy") : "Not set";
	};

	// Get availability date text for a session
	const getAvailabilityDateText = (session: any, index: number) => {
		if (session.startDate) {
			return formatDate(session.startDate);
		}
		if (index === 0) {
			return "Anytime";
		}
		return "As soon as previous session is completed";
	};

	// Copy text to clipboard
	const copyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text);
		// Optionally, show a tooltip or notification that the text was copied
	};

	// Get the title for a session
	const getSessionTitle = (session: any, index: number) => {
		if (session.title === "Pre-Reflection") return "Pre-Reflection";
		if (session.title === "Final Reflection") return "Final Reflection";
		return `Reflection ${index}`; // Changed from index + 1 to index
	};

	// Update the sortedSessions memo with proper typing
	const sortedSessions = React.useMemo(() => {
		if (assignmentType === "interview") {
			const sorted = getSortedInterviewSessions(values.stages, values.sessions);
			// Sort by stage type to ensure correct order (resume -> screening -> middle -> final)
			return sorted.sort((a, b) => {
				const stageOrder: Record<InterviewStageType, number> = {
					resume: 0,
					screening: 1,
					middle: 2,
					final: 3
				};
				return stageOrder[a.interviewStageType] - stageOrder[b.interviewStageType];
			});
		}

		if (!values.sessions) return [];
		return [...values.sessions].sort((a, b) => {
			if (a.title === "Pre-Reflection") return -1;
			if (b.title === "Pre-Reflection") return 1;
			if (a.title === "Final Reflection") return 1;
			if (b.title === "Final Reflection") return -1;
			return values.sessions.indexOf(a) - values.sessions.indexOf(b); // Maintain original order for other reflections
		});
	}, [values.sessions, values.stages, assignmentType]);

	// Handle assignment creation or update
	const handleCreateAssignment = async (status: "deployed" | "draft"): Promise<AdminAssignmentView | null> => {
		if (status === "deployed") {
			setIsDeployDialogOpen(true);
		} else {
			// Proceed with creating draft
			return await createAssignment(status);
		}
		return null;
	};

	// Create or update assignment
	const createAssignment = async (status: "deployed" | "draft"): Promise<AdminAssignmentView | null> => {
		setIsDeploying(true);
		try {
			// Ensure instageUser email is included in recipientList when not in admin path
			let updatedRecipientList = values.recipientList;
			if (!isAdminPath && instageUser?.email) {
				const emailList = values.recipientList ? values.recipientList.split(",").map((e: string) => e.trim()) : [];
				if (!emailList.includes(instageUser.email)) {
					emailList.push(instageUser.email);
					updatedRecipientList = emailList.join(",");
				}
			}

			const assignmentPayload = new AssignmentPayload(
				{
					title: values.title,
					adminEmail: instageUser?.email || "admin@instage.io",
					adminFirstName: instageUser?.firstName || "InStage",
					adminLastName: instageUser?.lastName || "",
					additionalReportRecipients: values.additionalReportRecipients,
					emailDeliveryOptions: values.emailDeliveryOptions,
					description: values.description,
					courseName: values.courseName,
					courseCode: values.courseCode,
					courseSection: values.courseSection,
					includeCSV: values.includeCSV,
					includePDF: values.includePDF,
					includeUserIdentification: values.includeUserIdentification,
					recipientList: updatedRecipientList,
					reportFrequency: values.reportFrequency,
					sendReminders: values.notify24HoursBefore,
					experienceType: values.experienceType,
					sessions: values.sessions.map((session: any, index: number) => {
						let date = null;
						let startDate = null;
						if (session.date) {
							const localDate = new Date(session.date);
							localDate.setHours(23, 59, 0, 0);
							date = localDate.toISOString();
						}
						if (session.startDate) {
							const localDate = new Date(session.startDate);
							localDate.setHours(0, 1, 0, 0);
							startDate = localDate.toISOString();
						}

						// For interview assignments, include interview-specific data
						if (assignmentType === "interview" && values.stages) {
							// Get enabled stages and sort them by their type in the desired order (Resume Review is included in initial stages but defaulted to null)
							const stageTypeOrder = ["resume", "screening", "middle", "final"];
							const enabledStages = values.stages
								.filter((stage: InterviewStage) => stage.enabled)
								.sort(
									(a: InterviewStage, b: InterviewStage) =>
										stageTypeOrder.indexOf(a.interviewStageType) - stageTypeOrder.indexOf(b.interviewStageType)
								);
							const currentStage = enabledStages[index];
							const stagePlan = values.stagePlan[currentStage.interviewStageOrderNumber];

							return new SchedulePayload({
								...session,
								date,
								startDate,
								interview: {
									interviewStageOrderNumber: currentStage?.interviewStageOrderNumber,
									interviewStageType: currentStage?.interviewStageType,
									jobTitle: values.staticJobTitle,
									jobDescription: values.jobDescription,
									interviewQuestionList: stagePlan?.interviewQuestionList || [],
									jobSummary: values.jobSummary || "",
									jobFunction: values.jobFunction || "",
									jobExperienceLevel: values.jobExperienceLevel || "",
									jobResponsibilities: values.jobResponsibilities || [],
									jobSkillsRequired: values.jobSkillsRequired || [],
									interviewerAttitude: stagePlan?.interviewerAttitude || ""
								}
							});
						}

						// For reflection assignments or if no stage data found
						return new SchedulePayload({
							...session,
							date,
							startDate
						});
					}),
					competencies: values.competencies,
					assignmentCode: values.assignmentCode,
					includeTranscript: values.includeTranscript,
					includeAudio: values.includeAudio,
					studentNotifications: values.studentNotifications || [],
					isPublished: status === "deployed" ? true : false,
					soloMode: !isAdminPath ? true : false
				},
				clientSettings
			);

			// console.log("assignmentPayload", assignmentPayload);

			if (isEditMode && assignmentId) {
				const data = await AssignmentService.updateAssignment(assignmentId, assignmentPayload);
				console.log("Assignment updated successfully:", data);
				onSubmit(data);
				return data;
			} else {
				const data = await AssignmentService.createAssignment(assignmentPayload);
				console.log("Assignment created successfully:", data);
				onSubmit(data);
				return data;
			}
		} catch (error) {
			console.error("Error creating assignment:", error);
		} finally {
			setIsDeploying(false);
			setIsDeployDialogOpen(false);
		}
		return null;
	};

	// Extract common button content into a component
	const DeployButton: React.FC<{ className?: string }> = ({ className }) => (
		<Button onClick={() => handleCreateAssignment("deployed")} disabled={isDeploying} className={className}>
			{isDeploying ? (
				<>
					<Loader2 className="mr-2 h-4 w-4 animate-spin" />
					Deploying...
				</>
			) : values.isPublished ? (
				isAdminPath ? (
					"Update Assignment"
				) : (
					"Update"
				)
			) : isAdminPath ? (
				"Deploy Assignment"
			) : (
				"Create"
			)}
		</Button>
	);

	const PreviousButton: React.FC<{ className?: string }> = ({ className }) => (
		<Button
			variant="secondary"
			onClick={() => {
				onPrevious();
			}}
			className={className}
		>
			Previous
		</Button>
	);

	// Helper function to get stage plan
	const getStagePlan = (stage: InterviewStage) => {
		return values.stagePlan?.[stage.interviewStageOrderNumber] || null;
	};

	// Helper function to get stage session
	const getStageSession = (stage: InterviewStage) => {
		return values.sessions?.[stage.interviewStageOrderNumber - 1] || null;
	};

	// Sort stages by order number for interview assignments
	const sortedStages = React.useMemo(() => {
		if (assignmentType !== "interview" || !values.stages) return [];
		return values.stages.slice().sort((a: InterviewStage, b: InterviewStage) => a.interviewStageOrderNumber - b.interviewStageOrderNumber);
	}, [values.stages, assignmentType]);

	// Get enabled stages
	const enabledStages = React.useMemo(() => {
		return sortedStages.filter((stage: InterviewStage) => stage.enabled);
	}, [sortedStages]);

	return (
		<div className="space-y-8">
			<h2 className="text-2xl font-semibold mb-6">Review Assignment</h2>

			{/* Sessions/Stages Panel */}
			<Panel title={assignmentType === "reflection" ? "Reflection Dates" : "Interview Dates"} onEdit={() => onEdit(0)}>
				{assignmentType === "reflection"
					? // Reflection sessions
					  sortedSessions.map((session, index) => {
							const sessionTitle = getSessionTitle(session, index);
							const reflectionNumber = sessionTitle.startsWith("Reflection") ? index : null;
							return (
								<div key={index} className="mb-6">
									<h4 className="font-semibold text-gray-800 mb-2">
										{reflectionNumber ? `Reflection ${reflectionNumber}` : sessionTitle}
									</h4>
									<Detail label="Due Date" value={formatDate(session.date)} />
									<Detail label="Available From" value={getAvailabilityDateText(session, index)} />
								</div>
							);
					  })
					: // Interview stages
					  enabledStages.map((stage: InterviewStage) => {
							const stagePlan = getStagePlan(stage);

							let stageDueDate = stage.date;
							let stageStartDate = stage.startDate;
							if (!stage.date || !stage.startDate) {
								const stageSession = getStageSession(stage);
								stageDueDate = stageSession?.date;
								stageStartDate = stageSession?.startDate;
							}

							return (
								<div key={stage.interviewStageOrderNumber} className="mb-6">
									<h4 className="font-semibold text-gray-800 mb-2">{stage.title}</h4>
									<Detail label="Due Date" value={formatDate(stageDueDate)} />
									<Detail label="Available From" value={formatDate(stageStartDate)} />
									{stagePlan && (
										<>
											<Detail label="Questions" value={`${stagePlan.interviewQuestionList?.length || 0} questions`} />
											<Detail
												label="Interviewer Attitude"
												value={
													(stagePlan.interviewerAttitude || "Neutral").charAt(0).toUpperCase() +
													(stagePlan.interviewerAttitude || "Neutral").slice(1)
												}
											/>
										</>
									)}
								</div>
							);
					  })}
			</Panel>

			<Panel title={assignmentType === "reflection" ? "Reflection Setup" : "Interview Setup"} onEdit={() => onEdit(1)}>
				{assignmentType === "reflection" && values.competencies && values.competencies.length > 0 && (
					// Reflection-specific details
					<div className="flex">
						<span className="font-normal text-gray-600 w-1/3">Selected Competencies:</span>
						<span className="text-gray-800">{values.competencies.join(", ")}</span>
					</div>
				)}
				{assignmentType === "interview" && (
					// Interview-specific details
					<Detail label="Job Title" value={values.jobTitleChoice === "Static" ? values.staticJobTitle : "Students will select"} />
				)}
			</Panel>

			{isAdminPath && (
				<>
					<Panel title="Assignment Details" onEdit={() => onEdit(2)}>
						<Detail label="Assignment Title" value={values.title} />
						<Detail label="Description" value={values.description} />
						{isAdminPath && (
							<>
								<Detail label="Course Name" value={values.courseName} />
								<Detail label="Course Code" value={values.courseCode} />
								<Detail label="Course Section" value={values.courseSection} />
							</>
						)}
					</Panel>

					<Panel title="Distribution" onEdit={() => onEdit(3)}>
						{values.recipientList && (
							<div className="mt-4">
								<h4 className="text-md font-normal text-gray-700 mb-2">Added Emails:</h4>
								<ul className="list-disc pl-5 space-y-1">
									{values.recipientList
										.split(",")
										.slice(0, 10)
										.map((email: string, index: number) => (
											<li key={index} className="text-sm text-gray-800">
												{email.trim()}
											</li>
										))}
									{values.recipientList.split(",").length > 10 && (
										<li className="text-sm text-gray-800">... {values.recipientList.split(",").length - 10} more</li>
									)}
								</ul>
							</div>
						)}
						<div className="mt-2">
							<h4 className="text-md font-normal text-gray-700 mb-2">Sign-up Link:</h4>
							<div className="flex items-center space-x-2">
								<span className="text-sm text-gray-800">{`https://www.reflection.instage.io/assignment/${
									values.assignmentCode || "ABC123"
								}`}</span>
								<Button
									variant="outline"
									size="icon"
									onClick={() =>
										copyToClipboard(`https://www.reflection.instage.io/assignment/${values.assignmentCode || "ABC123"}`)
									}
								>
									<ClipboardIcon className="h-5 w-5" />
								</Button>
							</div>
						</div>
					</Panel>

					<Panel title="Student Notifications" onEdit={() => onEdit(3)}>
						<Detail label="Send Enrollment Notification" value="Yes" />
						<Detail label="Send Report" value="Yes" />
						{clientSettings?.allowNotifyAvailabilityWindow && (
							<Detail
								label="Send Availability Reminders"
								value={values.studentNotifications?.includes(StudentNotications.notifyAvailabilityWindow) ? "Yes" : "No"}
							/>
						)}
						{clientSettings?.allowNotify24HoursBefore && (
							<Detail
								label="Send 24 Hour Notice Reminders"
								value={values.studentNotifications?.includes(StudentNotications.notify24HoursBefore) ? "Yes" : "No"}
							/>
						)}
						{clientSettings?.allowNotifyDueDate && (
							<Detail
								label="Send Reminder On Due Date"
								value={values.studentNotifications?.includes(StudentNotications.notifyDueDate) ? "Yes" : "No"}
							/>
						)}
					</Panel>

					<Panel title="Aggregated Summary Options" onEdit={() => onEdit(3)}>
						{clientSettings?.allowUserIdentification && (
							<Detail label="Include Student Name and Email" value={values.includeUserIdentification ? "Yes" : "No"} />
						)}
						{clientSettings?.allowTranscript && <Detail label="Include Transcript" value={values.includeTranscript ? "Yes" : "No"} />}
						{clientSettings?.allowAudio && <Detail label="Include Audio" value={values.includeAudio ? "Yes" : "No"} />}
						<Detail
							label="Report Frequency"
							value={
								values.reportFrequency === "afterEachDueDate"
									? "After each due date"
									: values.reportFrequency === "afterFinalDueDate"
									? "After final due date"
									: "No email reports"
							}
						/>
						{values.reportFrequency !== "noReports" && (
							<>
								<Detail label="Include CSV" value={values.includeCSV ? "Yes" : "No"} />
								<Detail label="Include DOCX" value={values.includePDF ? "Yes" : "No"} />
								{values.additionalReportRecipients && (
									<div className="mt-4">
										<h4 className="text-md font-normal text-gray-700 mb-2">Additional Report Recipients:</h4>
										<ul className="list-disc pl-5 space-y-1">
											{values.additionalReportRecipients.split(",").map((email: string, index: number) => (
												<li key={index} className="text-sm text-gray-800">
													{email.trim()}
												</li>
											))}
										</ul>
									</div>
								)}
							</>
						)}
						<p className="mt-2 text-sm text-gray-600 italic">
							Note: You can always log in to the dashboard to view assignment activity at any time.
						</p>
					</Panel>
				</>
			)}

			{/* Navigation and action buttons - desktop */}
			<div className="hidden md:flex justify-end mt-8 gap-2">
				<PreviousButton />
				<div className="space-x-4">
					{!values.isPublished && false && (
						<Button variant="outline" onClick={() => handleCreateAssignment("draft")}>
							Save Draft
						</Button>
					)}
					<DeployButton />
				</div>
			</div>

			{/* Navigation and action buttons - mobile */}
			<div className="flex gap-2 mt-8 md:hidden">
				<PreviousButton />
				<DeployButton />
			</div>

			{/* Deployment confirmation dialog */}
			<AlertDialog open={isDeployDialogOpen} onOpenChange={setIsDeployDialogOpen}>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>Confirm Assignment Deployment</AlertDialogTitle>
						<AlertDialogDescription>
							<ul className="list-disc pl-5 space-y-2">
								<li>Students will receive an email about the upcoming assignment.</li>
								<li>Due dates and competencies cannot be changed after deployment.</li>
								<li>You can add students up until the first session due date.</li>
							</ul>
						</AlertDialogDescription>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel onClick={() => setIsDeployDialogOpen(false)} disabled={isDeploying}>
							Cancel
						</AlertDialogCancel>
						<AlertDialogAction onClick={() => createAssignment("deployed")} disabled={isDeploying}>
							{isDeploying ? (
								<>
									<Loader2 className="mr-2 h-4 w-4 animate-spin" />
									Deploying...
								</>
							) : (
								"Confirm Deployment"
							)}
						</AlertDialogAction>
						{!values.isPublished && false && (
							<Button variant="outline" onClick={() => createAssignment("draft")} disabled={isDeploying}>
								Save as Draft
							</Button>
						)}
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</div>
	);
};

// Panel component for grouping related details
const Panel: React.FC<{ title: string; children: React.ReactNode; onEdit: () => void }> = ({ title, children, onEdit }) => (
	<div className="bg-white border border-[#00a9af] rounded-lg p-6">
		<div className="flex justify-between items-center mb-4">
			<h3 className="text-lg font-medium text-gray-800">{title}</h3>
			<Button variant="outline" onClick={onEdit}>
				<PencilIcon className="h-5 w-5 mr-1" />
				Edit
			</Button>
		</div>
		<div className="space-y-2">{children}</div>
	</div>
);

// Detail component for displaying individual details
const Detail: React.FC<{ label: string; value: string | number }> = ({ label, value }) => (
	<div className="flex">
		<span className="font-normal text-gray-600 w-1/2">{label}:</span>
		<span className="text-gray-800 font-['DM_Sans']">{value}</span>
	</div>
);

export default ReviewAssignment;
