import React, { useEffect, useState } from "react";
import { useAuth } from "@frontegg/react";
import { MagnifyingGlassIcon, DocumentIcon } from "@heroicons/react/24/solid";
import TokenManager from "../../services/TokenManager";

type FAQType = "security" | "general";
type FAQAudience = "students" | "admins" | "both";

interface FAQ {
	question: string;
	answer: string;
	type: FAQType;
	who: FAQAudience;
	helpDocLink?: string;
}

const SupportPage: React.FC<{}> = () => {
	const { user } = useAuth();

	const [faqs, setFaqs] = useState<FAQ[]>([
		{
			question: "What model of Reflection are we using?",
			answer: "We are using COBS but are looking at ways to be able to select which model you are hoping for.",
			type: "general",
			who: "admins"
		},
		{
			question: "How do I create a reflection assignment?",
			answer: "You can create a reflection assignment by going to the assignments page and clicking the 'Create Assignment' button. You can then fill out the details and click 'Deploy Assignment'.",
			type: "general",
			who: "admins",
			helpDocLink: "https://help.instagepractice.com/en/articles/9833546-how-to-create-a-reflection-assignment"
		},
		{
			question: "Can administrators create their own questions?",
			answer: "Not at this time, if it's something you think would add value please let us know.",
			type: "general",
			who: "admins"
		},
		{
			question: "Where is the data (including audio) stored and processed?",
			answer: "All data, including audio files, is processed and stored in Canada using AWS servers, with encryption applied in both transit and at rest.",
			type: "security",
			who: "admins"
		},
		{
			question: "Is it an open system AI tool or a closed system AI tool?",
			answer: "We use OpenAI. OpenAI does not train on any data submitted through the API (And neither do we).",
			type: "security",
			who: "admins"
		},
		{
			question: "What is the purpose of recording audio in the Instage tool?",
			answer: "The audio is recorded to create a speech-to-text transcription, which is used for reflection and practice sessions. The transcription helps capture the spoken words for later review.",
			type: "general",
			who: "both"
		},
		{
			question: "Who has access to the recorded audio?",
			answer: "The recorded audio is available to the student for personal review. If the instructor opts for it, the instructor can also access the file.",
			type: "security",
			who: "both"
		},
		{
			question: "How long is the audio data stored, and can it be deleted sooner?",
			answer: "Audio data is stored for 12 months by default. However, it can be deleted sooner by user request or based on school admin preferences. Users will soon have the ability to delete their files at any time, once the feature is live.",
			type: "security",
			who: "admins"
		},
		{
			question: "Is the audio data shared with third parties?",
			answer: "No, audio data is not shared with OpenAI or any other third-party services beyond the transcription service. Instage does not use the data for AI audio technology or data selling purposes.",
			type: "security",
			who: "admins"
		},
		{
			question: "Can I participate without having my voice recorded?",
			answer: "If you have privacy concerns regarding the use of the audio recording feature, you may inquire with your school admin or Instage for alternative methods of participation without engaging with this feature.",
			type: "general",
			who: "students"
		},
		{
			question: "Does Instage use the audio data for AI development?",
			answer: "No, Instage is not involved in AI audio technology development or adjacent fields. The audio recordings are solely used to support educational activities, and there is no intent to commercialize or collect this data for other purposes.",
			type: "security",
			who: "both"
		},
		{
			question: "How do I complete my reflection assignment?",
			answer: "You can complete your reflection assignment by navigating to the home page and selecting Start Session. If the call is ready to be completed, you will be able to do it via a phone call or on the web.",
			type: "general",
			who: "students",
			helpDocLink: "https://help.instagepractice.com/en/articles/9833736-how-to-run-a-reflection-session"
		}
	]);

	const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
	const [showAll, setShowAll] = useState<boolean>(false);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [selectedType, setSelectedType] = useState<FAQType | "all">("all");

	const toggleFAQ = (index: number) => {
		setExpandedIndex(expandedIndex === index ? null : index);
	};

	const toggleShowAll = () => {
		setShowAll(!showAll);
	};

	const filteredFaqs = faqs.filter(
		(faq) =>
			(selectedType === "all" || faq.type === selectedType) &&
			(faq.question.toLowerCase().includes(searchQuery.toLowerCase()) || faq.answer.toLowerCase().includes(searchQuery.toLowerCase())) &&
			(faq.who === "both" || (TokenManager.hasFacultyClearance() ? faq.who === "admins" : faq.who === "students"))
	);

	return (
		<>
			<p className="text-3xl font-bold text-[#16013e] mb-4">Support</p>
			<div className="rounded-lg border bg-card text-card-foreground mb-4 shadow-md">
				<div className="container mx-auto px-4 py-2">
					<h1 className="text-xl font-semibold text-primary-text font-plus-jakarta mb-1 mt-1">Need Help?</h1>
					<p className="text-black mb-4">
						We're here to assist you. Please send us a message through Intercom. 9am-6pm EST. Any messages sent outside of these hours
						will be responded to the next business day.
					</p>
					<div className="flex flex-row gap-2 items-center">
						<button
							className="inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none border border-input bg-background hover:bg-background hover:text-primary hover:border-primary h-10 px-4 py-2 text-base"
							onClick={() => window.Intercom("showMessages")}
						>
							Live Chat Support
						</button>
						<p className="text-black align-middle">Or</p>
						<button
							className="inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none border border-input bg-background hover:bg-background hover:text-primary hover:border-primary h-10 px-4 py-2 text-base"
							onClick={() => (window.location.href = "mailto:team@instage.io")}
						>
							Email Us
						</button>
					</div>
					<p className="text-black align-middle mb-4 mt-4">
						Please provide the following to your support agent: email, institution/company, and a detailed description of your issue.
					</p>
				</div>
			</div>

			<hr className="mb-4" />
			<div className="rounded-lg border bg-card text-card-foreground mb-4 shadow-md px-4 py-4">
				<h1 className="text-xl font-semibold text-primary-text font-plus-jakarta mb-2">FAQ</h1>
				<div className="flex items-center mb-4">
					<div className="relative">
						<span className="absolute left-3 top-1/2 transform -translate-y-1/2">
							<MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
						</span>
						<input
							type="text"
							placeholder="Search FAQs..."
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
							className="pl-10 pr-4 py-2 border border-gray-300 rounded-md" // Adjusted width
						/>
					</div>
					<div className="ml-4">
						{/* Added margin for spacing */}
						<div className="flex items-center">
							{/* Added flex container */}
							<label className="mr-2 w-auto">Filter by type:</label>
							<select
								value={selectedType}
								onChange={(e) => setSelectedType(e.target.value as FAQType | "all")}
								className="border border-gray-300 rounded-md p-2"
							>
								<option value="all">All</option>
								<option value="general">General</option>
								<option value="security">Security</option>
							</select>
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-4" style={{ maxHeight: showAll ? "400px" : "auto", overflowY: showAll ? "auto" : "visible" }}>
					{(showAll ? filteredFaqs : filteredFaqs.slice(0, 3)).map((faq, index) => (
						<div key={index} className="border border-gray-300 rounded-lg p-4 bg-white transition duration-300 hover:bg-gray-50">
							<button
								className={`w-full text-left font-semibold text-gray-800 flex justify-between items-center ${
									expandedIndex === index ? "text-primary" : ""
								}`}
								onClick={() => toggleFAQ(index)}
							>
								{faq.question}
								<div className="flex items-center">
									{faq.helpDocLink && (
										<a href={faq.helpDocLink} target="_blank" rel="noopener noreferrer" className="ml-2">
											<div className="flex items-center text-primary hover:text-teal-600">
												<p className="mr-1 font-normal w-50 text-sm">View Help Doc</p>
												<DocumentIcon className="h-5 w-5 " />
											</div>
										</a>
									)}
									<span className={`ml-2 transition-transform duration-300 ${expandedIndex === index ? "rotate-0" : "-rotate-90"}`}>
										{/* Chevron Icon */}
										<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
											<path
												fillRule="evenodd"
												d="M5.23 7.21a.75.75 0 011.06 0L10 10.44l3.71-3.23a.75.75 0 111.06 1.06l-4.25 3.5a.75.75 0 01-1.06 0l-4.25-3.5a.75.75 0 010-1.06z"
												clipRule="evenodd"
											/>
										</svg>
									</span>
								</div>
							</button>
							{expandedIndex === index && <p className="mt-2 text-gray-700">{faq.answer}</p>}
						</div>
					))}
				</div>
				{filteredFaqs.length > 3 && (
					<button className="mt-4 text-primary font-medium" onClick={toggleShowAll}>
						{showAll ? "View Less" : "View All"}
					</button>
				)}
			</div>
		</>
	);
};

export default SupportPage;
