import React from "react";
import { DataTable, ExtendedColumnDef, Filter } from "../shadcn-ui/DataTable";
import { Badge } from "../shadcn-ui/Badge";
import { Button } from "../shadcn-ui/Button";
import { DocumentIcon } from "@heroicons/react/24/solid";
import { SessionView } from "../../types/SessionView";
import { CompetencyProgress, GoalProgress } from "../../types/Goals";
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from "../shadcn-ui/Tooltip";
import { ActivityType } from "../../schemas/ActivityType";

interface StudentDetailsTableProps {
	sessions: SessionView[];
	type: "satisfaction" | "expectations" | "purpose" | "competency" | "finalReview" | "goalProgress";
	competencyName?: string;
	onNavigateToReport: (assignmentId: string, userId: string, scheduleId: string) => void;
}

interface TableRow {
	original: SessionView;
}

const tooltipTriggerStyles = "hover:text-[#00A9AF] transition-colors duration-200";

const StudentDetailsTable: React.FC<StudentDetailsTableProps> = ({ sessions, type, competencyName, onNavigateToReport }) => {
	const getBadgeVariant = (value: any, type: string) => {
		switch (type) {
			case "satisfaction":
				const score = Number(value);
				return score >= 8 ? "high" : score >= 6 ? "medium" : "low";
			case "expectations":
				return value?.toLowerCase() === "positive" ? "high" : value?.toLowerCase() === "mixed" ? "medium" : "low";
			case "purpose":
				return value === "Yes" ? "high" : value === "Partial" ? "medium" : value === "New" ? "new" : "low";
			case "competency":
				const assessment = Number(value);
				return assessment >= 8 ? "high" : assessment >= 6 ? "medium" : "low";
			case "finalReview":
				const rating = Number(value);
				return rating >= 8 ? "high" : rating >= 6 ? "medium" : "low";
			case "goalProgress":
				return value === "Yes" ? "high" : value === "Partial" ? "medium" : "low";
			default:
				return "medium";
		}
	};

	const getSortedSessions = () => {
		switch (type) {
			case "satisfaction":
				return [...sessions].sort((a, b) => (b.satisfaction?.score || 0) - (a.satisfaction?.score || 0));
			case "expectations":
				return [...sessions].sort((a, b) => {
					const order = { positive: 0, mixed: 1, negative: 2 };
					const aOutlook = (a.expectations?.outlook || "").toLowerCase();
					const bOutlook = (b.expectations?.outlook || "").toLowerCase();
					return order[aOutlook as keyof typeof order] - order[bOutlook as keyof typeof order];
				});
			case "purpose":
				return [...sessions].sort((a, b) => {
					const order = { Yes: 0, Partial: 1, No: 2, New: 3 };
					const aProgress = a.purpose?.progress || "No";
					const bProgress = b.purpose?.progress || "No";
					return order[aProgress as keyof typeof order] - order[bProgress as keyof typeof order];
				});
			case "competency":
				if (!competencyName) return sessions;
				return [...sessions].sort((a, b) => {
					const aCompetency = a.competencyProgress?.find((c) => c.name.toLowerCase() === competencyName?.toLowerCase());
					const bCompetency = b.competencyProgress?.find((c) => c.name.toLowerCase() === competencyName?.toLowerCase());
					return Number(bCompetency?.assessment || 0) - Number(aCompetency?.assessment || 0);
				});
			case "finalReview":
				return [...sessions].sort((a, b) => {
					return (b.finalReview?.feedback?.rating || 0) - (a.finalReview?.feedback?.rating || 0);
				});
			case "goalProgress":
				return [...sessions].sort((a, b) => {
					const aHighestStatus = getHighestGoalStatus(a.goalProgress);
					const bHighestStatus = getHighestGoalStatus(b.goalProgress);
					const order = { Yes: 0, Partial: 1, No: 2 };
					return order[aHighestStatus as keyof typeof order] - order[bHighestStatus as keyof typeof order];
				});
			default:
				return sessions;
		}
	};

	const getHighestGoalStatus = (goals: GoalProgress[]): string => {
		if (!Array.isArray(goals)) return "No";
		if (goals.some((g) => g.goal_status === "Yes")) return "Yes";
		if (goals.some((g) => g.goal_status === "Partial")) return "Partial";
		return "No";
	};

	const columns: ExtendedColumnDef<SessionView, any>[] = [
		{
			accessorKey: "fullName",
			header: "Student",

			cell: ({ row }) => (
				<div className="w-[125px]">
					<div className="truncate">
						{row.original.firstName} {row.original.lastName}
					</div>
				</div>
			)
		},
		{
			accessorKey: "email",
			header: "Email",
			cell: ({ row }) => (
				<div className="w-[200px]">
					<div className="truncate text-muted-foreground">{row.original.email}</div>
				</div>
			)
		},
		...(type === "goalProgress"
			? [
					{
						accessorKey: "goalProgress",
						header: "Goal Progress",
						cell: ({ row }: { row: TableRow }) => {
							const goals = row.original.goalProgress;
							if (!Array.isArray(goals) || goals.length === 0) return "N/A";

							return (
								<div className="flex flex-wrap gap-2">
									{goals.map((goal, idx) => (
										<Tooltip key={idx}>
											<TooltipTrigger asChild>
												<div>
													<Badge variant={getBadgeVariant(goal.goal_status, "goalProgress")}>{goal.goal_status}</Badge>
												</div>
											</TooltipTrigger>
											<TooltipContent className="bg-white border border-gray-200 shadow-lg" sideOffset={5}>
												<p className="max-w-xs break-words">{goal.summary}</p>
											</TooltipContent>
										</Tooltip>
									))}
								</div>
							);
						}
					},
					{
						accessorKey: "goals",
						header: "New Goals",
						cell: ({ row }: { row: TableRow }) => {
							const goals = row.original.goals;
							const activityType = row.original.activityType;

							if (!Array.isArray(goals) || goals.length === 0 || activityType === ActivityType.LAST) {
								return "No new goals";
							}

							return (
								<Tooltip>
									<TooltipTrigger asChild>
										<div className={tooltipTriggerStyles}>
											{goals.length} goal{goals.length !== 1 ? "s" : ""}
										</div>
									</TooltipTrigger>
									<TooltipContent className="bg-white border border-gray-200 shadow-lg" sideOffset={5}>
										{goals.map((goal, idx) => (
											<div key={idx} className="mb-2 last:mb-0">
												<p className="max-w-xs break-words">
													{idx + 1}. {goal.description}
												</p>
											</div>
										))}
									</TooltipContent>
								</Tooltip>
							);
						}
					}
			  ]
			: [
					{
						accessorKey: "score",
						header: type === "purpose" ? "Progress" : type === "expectations" ? "Outlook" : "Score",
						filterFn: (row: { original: SessionView }, columnId: string, filterValue: string) => {
							if (!filterValue) return true;

							let score: number | string;
							switch (type) {
								case "satisfaction":
									score = row.original.satisfaction?.score || 0;
									break;
								case "expectations":
									score = row.original.expectations?.outlook || "";
									break;
								case "purpose":
									score = row.original.purpose?.progress || "No";
									break;
								case "competency":
									const competency = row.original.competencyProgress?.find(
										(c: CompetencyProgress) => c.name.toLowerCase() === competencyName?.toLowerCase()
									);
									score = competency?.assessment || 0;
									break;
								case "finalReview":
									score = row.original.finalReview?.feedback?.rating || 0;
									break;
								default:
									score = 0;
							}

							switch (type) {
								case "satisfaction":
								case "competency":
								case "finalReview":
									const filterNum = Number(filterValue);
									const scoreNum = Number(score);
									if (filterNum === 8) return scoreNum >= 8;
									if (filterNum === 6) return scoreNum >= 6 && scoreNum < 8;
									if (filterNum === 0) return scoreNum < 6;
									return true;
								case "expectations":
									return score.toString().toLowerCase() === filterValue.toLowerCase();
								case "purpose":
									return score === filterValue;
								default:
									return true;
							}
						},
						cell: ({ row }: { row: TableRow }) => {
							const scoreValue = (() => {
								switch (type) {
									case "satisfaction":
										return row.original.satisfaction?.score || 0;
									case "expectations":
										return row.original.expectations?.outlook
											? row.original.expectations.outlook.charAt(0).toUpperCase() + row.original.expectations.outlook.slice(1)
											: "Unknown";
									case "purpose":
										return row.original.purpose?.progress || "No";
									case "competency":
										const competency = row.original.competencyProgress?.find(
											(c: CompetencyProgress) => c.name.toLowerCase() === competencyName?.toLowerCase()
										);
										return competency?.assessment || 0;
									case "finalReview":
										return row.original.finalReview?.feedback?.rating || 0;
									default:
										return 0;
								}
							})();

							return (
								<div>
									<Badge variant={getBadgeVariant(scoreValue, type)}>{scoreValue}</Badge>
								</div>
							);
						}
					},
					{
						accessorKey: "detail",
						header: type === "finalReview" ? "Preferred Method" : "Summary",
						cell: ({ row }: { row: TableRow }) => {
							const detailValue = (() => {
								switch (type) {
									case "satisfaction":
										return row.original.satisfaction?.reason || "No reason provided";
									case "expectations":
										return row.original.expectations?.content || "No expectations provided";
									case "purpose":
										return row.original.purpose?.summary || "No summary provided";
									case "competency":
										const competency = row.original.competencyProgress?.find(
											(c) => c.name.toLowerCase() === competencyName?.toLowerCase()
										);
										return competency?.summary || "No summary provided";
									case "finalReview":
										return row.original.finalReview?.feedback?.preferredMethod || "unknown";
									default:
										return "";
								}
							})();

							if (type === "finalReview") {
								return (
									<Badge
										variant={detailValue === "guided" ? "high" : detailValue === "traditional" ? "low" : "outline"}
										className="capitalize"
									>
										{detailValue}
									</Badge>
								);
							}

							return (
								<div className="w-[400px]">
									<Tooltip>
										<TooltipTrigger asChild>
											<div className={`truncate ${tooltipTriggerStyles}`}>{detailValue}</div>
										</TooltipTrigger>
										<TooltipContent className="bg-white border border-gray-200 shadow-lg" sideOffset={5}>
											<p className="max-w-xs break-words">{detailValue}</p>
										</TooltipContent>
									</Tooltip>
								</div>
							);
						}
					}
			  ]),
		{
			accessorKey: "report",
			header: "Report",
			cell: ({ row }: { row: TableRow }) => (
				<Button
					variant="link"
					onClick={() => onNavigateToReport(row.original.assignmentId, row.original.userId, row.original.scheduleId)}
					className="flex space-x-1 p-0"
				>
					<DocumentIcon className="h-4 w-4" />
					<span>View</span>
				</Button>
			)
		}
	];

	const getFilterOptions = () => {
		switch (type) {
			case "satisfaction":
			case "competency":
			case "finalReview":
				return [
					{ label: "High (8-10)", value: "8" },
					{ label: "Medium (6-7)", value: "6" },
					{ label: "Low (0-5)", value: "0" }
				];
			case "expectations":
				return [
					{ label: "Positive", value: "positive" },
					{ label: "Mixed", value: "mixed" },
					{ label: "Negative", value: "negative" }
				];
			case "purpose":
				return [
					{ label: "Yes", value: "Yes" },
					{ label: "Partial", value: "Partial" },
					{ label: "No", value: "No" },
					{ label: "New", value: "New" }
				];
			case "goalProgress":
				return [
					{ label: "Yes", value: "Yes" },
					{ label: "Partial", value: "Partial" },
					{ label: "No", value: "No" }
				];
			default:
				return [];
		}
	};

	const filters: Filter[] = [
		{
			columnId: "score",
			type: "select",
			placeholder: type === "purpose" ? "Progress" : type === "expectations" ? "Outlook" : "Score",
			options: getFilterOptions()
		}
	];

	return (
		<TooltipProvider>
			<DataTable columns={columns} data={getSortedSessions()} filters={filters} />
		</TooltipProvider>
	);
};

export default StudentDetailsTable;
