import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "../../lib/utils";

const buttonVariants = cva(
	"inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50",
	{
		variants: {
			variant: {
				default: "bg-primary text-primary-foreground hover:bg-primary/90 disabled:cursor-not-allowed",
				warning: "bg-warning text-warning-foreground hover:bg-warning/90 disabled:cursor-not-allowed",
				destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90 disabled:cursor-not-allowed",
				outline:
					"border border-primary text-primary bg-white hover:bg-[#e6f7f7] hover:text-primary hover:border-primary disabled:cursor-not-allowed",
				outlineTransparent: "border border-primary bg-transparent hover:bg-background hover:text-primary disabled:cursor-not-allowed",
				outlineWarning:
					"text-warning border border-warning bg-transparent hover:bg-warning hover:text-warning-foreground disabled:cursor-not-allowed",
				secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80 disabled:cursor-not-allowed",
				ghost: "hover:text-accent-foreground disabled:cursor-not-allowed",
				link: "text-primary underline-offset-4 hover:underline disabled:cursor-not-allowed",
				disabled: "bg-muted text-disabled-text cursor-not-allowed"
			},
			size: {
				default: "h-9 px-4 py-2 text-base",
				sm: "h-9 rounded-md px-3 text-sm",
				lg: "h-11 rounded-md px-8 text-lg",
				icon: "h-9 w-9"
			}
		},
		defaultVariants: {
			variant: "default",
			size: "default"
		}
	}
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
	asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, size, asChild = false, disabled, ...props }, ref) => {
	const Comp = asChild ? Slot : "button";
	return (
		<Comp
			className={cn(buttonVariants({ variant: disabled ? "disabled" : variant, size, className }))}
			ref={ref}
			disabled={disabled}
			{...props}
		/>
	);
});
Button.displayName = "Button";

export { Button, buttonVariants };
