import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { jwtDecode } from "jwt-decode";
// import { plainToInstance } from "class-transformer";
import { Report } from "../Utility/Report";
import { SessionView } from "../../types/SessionView";
// import { VapiCallEndedReason } from "../../types/vapiEndCallReasons";
import { SessionService } from "../../services/SessionService";

const EmailReportPostSession: React.FC = () => {
	const { token } = useParams<{ token: string }>();
	const [sessionData, setSessionData] = useState<SessionView | null>(null);
	// console.log(token);

	useEffect(() => {
		if (token) {
			console.log("token", token);
			SessionService.getSessionEmail(token).then((data) => setSessionData(data));
		}
	}, [token]);

	// const mockSessionData: SessionView = {
	// 	sessionId: "550e8400-e29b-41d4-a716-446655440000",
	// 	isSubmitted: true,
	// 	comments: [],
	// 	userId: "550e8400-e29b-41d4-a716-446655440001",
	// 	scheduleId: "550e8400-e29b-41d4-a716-446655440002",
	// 	scheduleDescription: "Weekly review session",
	// 	scheduleDate: new Date("2023-10-01T10:00:00Z"),
	// 	isActive: true,
	// 	expiresAt: null,
	// 	activityType: ActivityType.FIRST,
	// 	assignedSessionId: "550e8400-e29b-41d4-a716-446655440003",
	// 	assignmentId: "550e8400-e29b-41d4-a716-446655440004",
	// 	grade: 85,
	// 	isLate: false,
	// 	submittedAt: new Date(),
	// 	courseId: "550e8400-e29b-41d4-a716-446655440005",
	// 	courseName: "Introduction to Programming",
	// 	courseCode: "CS101",
	// 	courseTerm: "Fall 2023",
	// 	courseSection: "A",
	// 	simulationDataId: "550e8400-e29b-41d4-a716-446655440006",
	// 	sessionType: "Type A",
	// 	goals: [
	// 		{
	// 			description: "Understand basic programming concepts"
	// 		},
	// 		{
	// 			description: "Write simple programs in Python"
	// 		}
	// 	],
	// 	goalProgress: [
	// 		{
	// 			description: "description",
	// 			summary: "summary",
	// 			isReviewed: true,
	// 			goal_status: "Yes"
	// 		},
	// 		{
	// 			description: "description",
	// 			summary: "summary",
	// 			isReviewed: true,
	// 			goal_status: "Yes"
	// 		}
	// 	],
	// 	overview: "This session covers the basics of programming.",
	// 	expectations: {
	// 		content: "I expect to gain a solid foundation in programming concepts and start writing simple programs.",
	// 		outlook: "positive"
	// 	},
	// 	satisfaction: { reason: "Very helpful", score: 5 },
	// 	competencyProgress: [
	// 		{
	// 			name: "problem-solving",
	// 			summary: "Improved ability to break down complex problems into smaller, manageable parts",
	// 			assessment: "7"
	// 		},
	// 		{
	// 			name: "coding",
	// 			summary: "Gained confidence in writing basic Python scripts",
	// 			assessment: "6"
	// 		}
	// 	],
	// 	purpose: {
	// 		description: "description",
	// 		summary: "summary",
	// 		progress: "Yes"
	// 	},
	// 	finalReview: {
	// 		takeaways: [
	// 			{
	// 				point: "description",
	// 				example: "example"
	// 			}
	// 		],
	// 		growth: "description",
	// 		feedback: {
	// 			valuableAspects: "description",
	// 			contribution: "description",
	// 			suggestions: "description",
	// 			preferredMethod: "guided",
	// 			rating: 5
	// 		}
	// 	},
	// 	noteworthyComments: {
	// 		positive: [
	// 			{
	// 				comment: "test",
	// 				priority: "low"
	// 			}
	// 		],
	// 		negative: [
	// 			{
	// 				comment: "test",
	// 				priority: "low"
	// 			}
	// 		]
	// 	},
	// 	recordingUrls: ["http://example.com/recording1", "http://example.com/recording2"],
	// 	chatHistory: [
	// 		{
	// 			id: "chat-001",
	// 			content: "Hello, how can I help you?",
	// 			role: "assistant",
	// 			createdAt: new Date("2023-10-01T10:00:00Z")
	// 		}
	// 	],
	// 	includeTranscript: true,
	// 	includeAudio: false,
	// 	userConfirmed: false,
	// 	endedReason: VapiCallEndedReason.AssistantError,
	// 	reviewCountYes: 10,
	// 	reviewCountPartial: 5,
	// 	reviewCountNo: 2,
	// 	reviewPercentage: 75
	// };

	// if (!sessionData) {
	// 	setSessionData(mockSessionData);
	// }

	// const [isSubmitting, setIsSubmitting] = useState(false);
	// const [isSubmitted, setIsSubmitted] = useState(!isDateNull(sessionData.submittedAt));

	// const [submissionTime, setSubmissionTime] = useState<string | null>(
	// 	sessionData.submittedAt
	// 		? sessionData.submittedAt.toLocaleString("en-US", {
	// 				timeZone: "EST",
	// 				weekday: "long",
	// 				year: "numeric",
	// 				month: "long",
	// 				day: "numeric",
	// 				hour: "numeric",
	// 				minute: "numeric",
	// 				hour12: true
	// 		  })
	// 		: null
	// );

	// const handleSubmit = async () => {
	// 	setIsSubmitting(true);
	// 	try {
	// 		if (!sessionData || !sessionData.sessionId || !sessionData.userId) {
	// 			console.log(sessionData?.sessionId, sessionData?.userId);
	// 			throw new Error("Session ID or User ID is missing");
	// 		}
	// 		const response = await AssignedSessionService.submitSession(sessionData.sessionId, sessionData.userId, 100, true, token);
	// 		setSubmissionTime(new Date().toLocaleString("en-US", { timeZone: "EST", hour12: true }));
	// 		setIsSubmitted(true);
	// 	} catch (error) {
	// 		console.error("Submission failed", error);
	// 	} finally {
	// 		setIsSubmitting(false);
	// 	}
	// };

	return (
		<div className="email-report-post-session" style={{ marginTop: "20px" }}>
			{sessionData && (
				<Report selectedUserSession={sessionData} userName={sessionData.firstName || "student"} satisfactionData={[]} competencyData={{}} />
			)}
		</div>
	);
};

export default EmailReportPostSession;
