import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "../shadcn-ui/Card";
import { AssignedSessionView } from "../../types/AssignedSessionView";

interface UserInterviewPlanProps {
	selectedAssignedSession: AssignedSessionView;
}

const INTERVIEWER_ATTITUDES = {
	neutral: "Neutral",
	direct: "Direct",
	friendly: "Friendly"
} as const;

const UserInterviewPlan: React.FC<UserInterviewPlanProps> = ({ selectedAssignedSession }) => {
	if (!selectedAssignedSession) return null;

	// Sort questions by order
	const sortedQuestions = [...(selectedAssignedSession.interviewQuestionList || [])].sort((a, b) => a.order - b.order);

	return (
		<div className="mt-12">
			<h2 className="text-xl md:text-2xl font-semibold mb-4">Interview Plan - {selectedAssignedSession.scheduleDescription}</h2>
			<Card className="mb-6">
				<CardHeader className="pb-0">
					<CardTitle className="text-lg font-semibold text-[#16013e]">Questions</CardTitle>
				</CardHeader>
				<CardContent>
					{/* Question List */}
					<div className="space-y-2">
						<div className="space-y-2">
							{sortedQuestions.map((question) => (
								<div key={question.id} className="flex items-center justify-between p-4 bg-white border border-gray-200 rounded-lg">
									<div className="flex-1">
										<span className="text-sm text-gray-600">
											{question.text === "auto" ? "[Auto-generated question]" : question.text}
										</span>
									</div>
									<span className="px-3 py-1 text-sm rounded-full bg-gray-100 text-primary-text">{question.type}</span>
								</div>
							))}
						</div>
					</div>

					{/* Interviewer Attitude */}
					{/* <div className="space-y-2">
							<h4 className="text-sm font-medium text-gray-700">Interviewer Attitude</h4>
							<div className="text-sm text-gray-600">
								{selectedAssignedSession.interviewerAttitude
									? INTERVIEWER_ATTITUDES[selectedAssignedSession.interviewerAttitude as keyof typeof INTERVIEWER_ATTITUDES]
									: "Neutral"}
							</div>
						</div> */}
				</CardContent>
			</Card>
		</div>
	);
};

export default UserInterviewPlan;
