import { useEffect, useState, useRef, useCallback } from "react";
import { io, Socket } from "socket.io-client";
export enum SocketStatus {
	CONNECTED = "connected",
	DISCONNECTED = "disconnected",
	CONNECTING = "connecting",
	ERROR = "error"
}
interface SocketResponse<T = unknown> {
	status: string;
	error?: string;
	data?: T;
}
interface SocketEmit {
	<T = unknown>(eventName: string, data?: object, timeout?: number): Promise<T>;
}
const useSocket = ({ url }: { url: string }) => {
	const socketRef = useRef<Socket | null>(null);
	const [status, setStatus] = useState<SocketStatus>(SocketStatus.CONNECTING);

	useEffect(() => {
		let isMounted = true;

		const initSocket = () => {
			if (!socketRef.current) {
				const socketInstance = io(url, { transports: ["websocket"] });
				socketRef.current = socketInstance;

				socketInstance.on("connect", () => {
					if (isMounted) {
						setStatus(SocketStatus.CONNECTED);
						console.log("Connected to socket server");
					}
				});

				socketInstance.on("error", (error) => {
					if (isMounted) {
						setStatus(SocketStatus.ERROR);
						console.error("Error connecting to socket server", error);
					}
				});

				socketInstance.on("connect_error", (error) => {
					if (isMounted) {
						setStatus(SocketStatus.ERROR);
						console.error("Connection error:", error);
					}
				});

				socketInstance.on("disconnect", () => {
					if (isMounted) {
						setStatus(SocketStatus.DISCONNECTED);
						console.log("Disconnected from socket server");
					}
				});

				socketInstance.on("incidentStatusUpdate", (data) => {
					console.log("incidentStatusUpdate received", data);
				});
			}
		};

		initSocket();

		return () => {
			isMounted = false;
			if (socketRef.current) {
				console.log("Cleaning up socket connection");
				socketRef.current.removeAllListeners();
				socketRef.current.close();
				socketRef.current = null;
			}
		};
	}, [url]);

	// TODO add class validation
	const emit = useCallback(
		<T = unknown>(eventName: string, data = {}, timeout = 5000): Promise<T> => {
			return new Promise((resolve, reject) => {
				if (status === SocketStatus.CONNECTED) {
					const timeoutId = setTimeout(() => {
						reject(new Error(`Socket request timed out for event: ${eventName}`));
					}, timeout);

					socketRef.current?.emit(eventName, data, (response: SocketResponse) => {
						clearTimeout(timeoutId);

						if (response && response.error) {
							console.error(response.error);
							reject(new Error(response.error));
							return;
						}

						const { status, error, ...rest } = response;
						const restData = rest as Record<string, unknown>;
						const keys = Object.keys(restData);

						if (keys.length === 1) {
							const value = restData[keys[0]];
							if (Array.isArray(value)) {
								resolve(value as T);
							} else {
								resolve(value as T);
							}
						} else {
							resolve(restData as T);
						}
					});
				} else {
					reject(new Error("Socket is not connected"));
				}
			});
		},
		[status]
	);

	const on = useCallback(
		(eventName: string, callback: (...args: any[]) => void) => {
			socketRef.current?.on(eventName, callback);
		},
		[status]
	);

	const off = useCallback(
		(eventName: string, callback: (...args: any[]) => void) => {
			socketRef.current?.off(eventName, callback);
		},
		[status]
	);

	const disconnect = useCallback(() => {
		socketRef.current?.disconnect();
	}, []);
	return {
		emit,
		on,
		off,
		disconnect,
		status
	};
};
export default useSocket;
