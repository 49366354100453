import axios from "axios";
import { UserAssignmentView } from "../types/UserAssignmentView";
import { plainToInstance } from "class-transformer";
import { AdminAssignmentView } from "../types/AdminAssignmentView";
import { AssignmentPayload } from "../types/AssignmentPayload";
import { CompetencyView } from "../types/CompetencyView";
import { CompetencyPayload } from "../types/CompetencyPayload";
import { LogAxiosError } from "./AxiosUtility";
import { SchedulePayload } from "../types/SchedulePayload";
import { InterviewPayload } from "../types/InterviewPayload";

interface SessionDateInfo {
	sessions: SchedulePayload[];
}

export interface JobPosting {
	title: string;
	companyName: string;
	location: string;
	postingId: string;
	url?: string;
	dateCreated?: string;
	summary?: string;
	jobId?: string;
	score?: number;
	monsterUrl?: string;
}

export interface JobPostingDetails {
	title: string;
	monsterUrl?: string;
	jobBody?: string;
}

export class AssignmentService {
	private static baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/user-assignments`;
	private static createAssignmentUrl = `${process.env.REACT_APP_BACKEND_URL}/api/customer-portal`;

	static async getClientNameAndIdFromAssignmentCode(
		assignmentCode: string
	): Promise<{ clientName: string; assignmentId: string; assignmentTitle: string; tenantId: string; clientId: string }> {
		const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/client/assignment-code/${assignmentCode}`);
		console.log("Assignment code response", response.data);
		return response.data;
	}

	static async getAll(): Promise<UserAssignmentView[]> {
		try {
			return this.fetchUserAssignments(`${this.baseUrl}/`);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `all assignments`);
			throw new Error(errorMessage);
		}
	}

	static async getUserViewById(assignmentId: string): Promise<UserAssignmentView> {
		try {
			return this.fetchUserAssignment(`${this.baseUrl}/assignment/${assignmentId}`);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `assignment id ${assignmentId}`);
			throw new Error(errorMessage);
		}
	}

	static async getAdminViewById(assignmentId: string): Promise<AdminAssignmentView> {
		try {
			return this.fetchAdminAssignment(`${this.baseUrl}/admin/${assignmentId}`);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `assignment id ${assignmentId}`);
			throw new Error(errorMessage);
		}
	}
	static async getByUserId(userId: string): Promise<UserAssignmentView[]> {
		try {
			return this.fetchUserAssignments(`${this.baseUrl}/user/${userId}`);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `user id ${userId}`);
			throw new Error(errorMessage);
		}
	}

	static async getByCourseId(courseId: string): Promise<UserAssignmentView[]> {
		try {
			return this.fetchUserAssignments(`${this.baseUrl}/course/${courseId}`);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `course id ${courseId}`);
			throw new Error(errorMessage);
		}
	}

	static async getAllAdminView(): Promise<AdminAssignmentView[]> {
		try {
			return this.fetchAdminAssignments(`${this.baseUrl}/admin`);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `all admin assignments`);
			throw new Error(errorMessage);
		}
	}

	static async getAllAdminViewDebug(): Promise<AdminAssignmentView[]> {
		try {
			return this.fetchAdminAssignments(`${this.baseUrl}/admin-debug`);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `all admin assignments debug`);
			throw new Error(errorMessage);
		}
	}

	static async getUpdatedCallMeNowUrl(authToken: string): Promise<string> {
		console.log("");

		const token = authToken.split(" ")[0];
		try {
			// console.log("admin assignment authToken", token);
			const response = await axios.get(`${this.baseUrl}/updated-call-me-now-link`, {
				headers: { Authorization: `Bearer ${token}`, skipAuthRefresh: true }
			});
			return response.data.link;
		} catch (error) {
			const errorMessage = LogAxiosError(error, `updated call me now link`);
			throw new Error(errorMessage);
		}
	}

	static async getAssignmentEmail(authToken: string): Promise<AdminAssignmentView> {
		console.log("");

		console.log(`${this.baseUrl}/email`);
		const token = authToken.split(" ")[0];
		// console.log("admin assignment authToken", token);
		try {
			console.log("admin assignment authToken", token);
			const response = await axios.get(`${this.baseUrl}/email`, { headers: { Authorization: `Bearer ${token}` } });
			return plainToInstance(AdminAssignmentView, response.data);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `assignment email`);
			throw new Error(errorMessage);
		}
	}

	static async getNewAssignmentCode(): Promise<string> {
		try {
			const response = await axios.get(`${this.baseUrl}/new-assignment-code`);
			return response.data.code;
		} catch (error) {
			const errorMessage = LogAxiosError(error, `new assignment code`);
			throw new Error(errorMessage);
		}
	}

	static async createAssignment(payload: AssignmentPayload): Promise<AdminAssignmentView> {
		try {
			const response = await axios.post(`${this.baseUrl}/create-assignment`, { payload });
			console.log("Assignment created successfully2:", response.data);
			return response.data.assignment;
		} catch (error) {
			const errorMessage = LogAxiosError(error, `create assignment`);
			throw new Error(errorMessage);
		}
	}

	static async updateAssignment(assignmentId: string, payload: AssignmentPayload): Promise<AdminAssignmentView> {
		try {
			const response = await axios.post(`${this.baseUrl}/update-assignment`, { assignmentId, payload });
			console.log("updateAssignment response:", response.data);
			return response.data.assignment;
		} catch (error) {
			const errorMessage = LogAxiosError(error, `update assignment ${assignmentId}`);
			throw new Error(errorMessage);
		}
	}

	static async getAssignmentPayloadById(assignmentId: string): Promise<AssignmentPayload> {
		try {
			const response = await axios.get(`${this.baseUrl}/assignment-payload/${assignmentId}`);
			return response.data;
		} catch (error) {
			const errorMessage = LogAxiosError(error, `assignment payload by id ${assignmentId}`);
			throw new Error(errorMessage);
		}
	}

	static async addUserToAssignment(assignmentId: string, userId: string): Promise<void> {
		try {
			await axios.post(`${this.baseUrl}/add-user-to-assignment`, { assignmentId, userId });
		} catch (error) {
			const errorMessage = LogAxiosError(error, `add user to assignment ${assignmentId} ${userId}`);
			throw new Error(errorMessage);
		}
	}

	static async addUserToAssignmentByEmail(assignmentId: string, email: string): Promise<void> {
		try {
			await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user-assignments/addUserByEmail`, { assignmentId, email });
		} catch (error) {
			const errorMessage = LogAxiosError(error, `add user to assignment by email ${assignmentId} ${email}`);
			throw new Error(errorMessage);
		}
	}

	static async createAndAddUserToAssignment(assignmentId: string, email: string): Promise<void> {
		try {
			await axios.post(`${this.baseUrl}/create-and-add-user-to-assignment`, { assignmentId, email });
		} catch (error) {
			const errorMessage = LogAxiosError(error, `create and add user to assignment ${assignmentId} ${email}`);
			throw new Error(errorMessage);
		}
	}
	static async removeUserFromAssignment(assignmentId: string, userId: string): Promise<void> {
		try {
			await axios.post(`${this.baseUrl}/remove-user-from-assignment`, { assignmentId, userId });
		} catch (error) {
			const errorMessage = LogAxiosError(error, `remove user from assignment ${assignmentId} ${userId}`);
			throw new Error(errorMessage);
		}
	}

	static async checkIfUserIsInAssignment(assignmentId: string, userId: string): Promise<boolean> {
		try {
			const response = await axios.get(`${this.baseUrl}/check-user-assignment`, {
				params: { assignmentId, userId }
			});
			return response.data.isInAssignment;
		} catch (error) {
			const errorMessage = LogAxiosError(error, `${assignmentId} ${userId}`);
			throw new Error(errorMessage);
		}
	}

	static async getCompetenciesViewByClientId(): Promise<CompetencyView[]> {
		try {
			const response = await axios.get(`${this.baseUrl}/competencies-view`, {});

			const competencies = plainToInstance(CompetencyView, response.data as CompetencyView[]);
			return competencies;
		} catch (error) {
			const errorMessage = LogAxiosError(error, `competencies view by client id`);
			throw new Error(errorMessage);
		}
	}

	static async createCustomCompetency(payload: CompetencyPayload): Promise<void> {
		try {
			await axios.post(`${this.baseUrl}/create-custom-competency`, { payload });
		} catch (error) {
			const errorMessage = LogAxiosError(error, `create custom competency`);
			throw new Error(errorMessage);
		}
	}

	static async getSessionDateInfo(assignmentId: string): Promise<SessionDateInfo> {
		try {
			const response = await axios.get(`${this.baseUrl}/session-dates/${assignmentId}`);
			return {
				sessions: response.data.sessions.map(
					(session: any) =>
						new SchedulePayload({
							title: session.title || "",
							date: session.date,
							startDate: session.startDate || null,
							interview: session.interview ? new InterviewPayload(session.interview) : undefined
						})
				)
			};
		} catch (error) {
			const errorMessage = LogAxiosError(error, `session dates for assignment ${assignmentId}`);
			throw new Error(errorMessage);
		}
	}

	private static async fetchUserAssignments(url: string): Promise<UserAssignmentView[]> {
		try {
			const response = await axios.get(url);
			return response.data.map((assignment: object) => plainToInstance(UserAssignmentView, assignment));
		} catch (error) {
			console.error("Error fetching user assignments:", error);
			throw error;
		}
	}

	private static async fetchUserAssignment(url: string): Promise<UserAssignmentView> {
		try {
			const response = await axios.get(url);
			return plainToInstance(UserAssignmentView, response.data);
		} catch (error) {
			console.error("Error fetching user assignment:", error);
			throw error;
		}
	}

	private static async fetchAdminAssignment(url: string): Promise<AdminAssignmentView> {
		try {
			const response = await axios.get(url);
			return plainToInstance(AdminAssignmentView, response.data);
		} catch (error) {
			console.error("Error fetching admin assignment:", error);
			throw error;
		}
	}

	private static async fetchAdminAssignments(url: string): Promise<AdminAssignmentView[]> {
		try {
			const response = await axios.get(url);
			return plainToInstance(AdminAssignmentView, response.data as AdminAssignmentView[]);
		} catch (error) {
			console.error("Error fetching admin assignments:", error);
			throw error;
		}
	}

	static async searchJobPostings(title: string): Promise<JobPosting[]> {
		try {
			const response = await axios.get(`${this.baseUrl}/search-job-postings`, {
				params: { title }
			});
			return response.data;
		} catch (error) {
			const errorMessage = LogAxiosError(error, `search job postings ${title}`);
			throw new Error(errorMessage);
		}
	}

	static async getJobPosting(postingId: string): Promise<JobPostingDetails> {
		try {
			const response = await axios.get(`${this.baseUrl}//get-job-posting/${postingId}`);
			return response.data;
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get job posting ${postingId}`);
			throw new Error(errorMessage);
		}
	}

	static async generateJobDetails(assignmentId: string, payload: { jobTitle: string; jobDescription: string }): Promise<void> {
		try {
			await axios.post(`${this.baseUrl}/generate-job-details/${assignmentId}`, payload);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `generate job details ${assignmentId}`);
			throw new Error(errorMessage);
		}
	}
}
