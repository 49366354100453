import React from "react";
import AudioPlayer from "./AudioPlayer";
import { Message } from "../../types/SessionView";

interface ChatHistoryProps {
	messages: Message[];
	userName: string;
	recordingUrls?: string[];
}

const ChatHistory: React.FC<ChatHistoryProps> = ({ messages, userName, recordingUrls }) => {
	const hasRecordingUrls = recordingUrls && recordingUrls.length > 0 && Array.isArray(recordingUrls);
	return (
		<div className="bg-white border border-[#eaecf0] rounded-lg p-6">
			<div className="flex justify-between items-center mb-4">
				<h2 className="text-xl font-semibold text-[#16013e]">Chat History</h2>
				{hasRecordingUrls && (
					<div className="flex flex-col space-y-2">
						<AudioPlayer recordingUrl={recordingUrls[0]} />
					</div>
				)}
			</div>

			<div className="space-y-4 max-h-[600px] overflow-y-auto">
				{messages.map((message) => (
					<div key={message.id} className={`flex ${message.role === "user" ? "justify-end" : "justify-start"}`}>
						<div className="flex flex-col max-w-[80%]">
							<div className={`p-3 rounded-lg ${message.role === "user" ? "bg-[#00a9af] text-white" : "bg-[#F6F3ED] text-gray-900"}`}>
								<p className={`text-xs font-medium mb-2 ${message.role === "user" ? "text-white opacity-90" : "text-gray-600"}`}>
									{message.role === "user" ? userName : "AI Guide"}
								</p>
								<p>{message.content}</p>
								<p className={`text-xs mt-2 ${message.role === "user" ? "text-white opacity-75" : "text-gray-500"}`}>
									{new Date(message.createdAt).toLocaleString()}
								</p>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default ChatHistory;
